import React, { useState } from "react";

function FileUpload({ id, fileImage, setFileImage, setFileName }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      setFileName((prev) => ({
        ...prev,
        [id]: newFile,
      }));
      setFileImage((prev) => ({
        ...prev,
        [id]: URL.createObjectURL(newFile),
      }));
    }
  };

  const handleImageDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setFileName((prev) => ({
      ...prev,
      [id]: "",
    }));
    setFileImage((prev) => ({
      ...prev,
      [id]: "",
    }));
  };

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      <label
        htmlFor={`fileInput${id}`}
        className={`editLocationPictures ${fileImage[id] ? "has-image" : ""}`}
        style={
          fileImage[id]
            ? { position: "relative", display: "inline-block" }
            : { display: "flex" }
        }>
        {fileImage[id] ? (
          <>
            <img
              src={fileImage[id]}
              alt=''
              className='editLocationPictures'
              onClick={handleImageDelete}
            />
            {isHovering && (
              <div className='delete-overlay' onClick={handleImageDelete}>
                X
              </div>
            )}
          </>
        ) : (
          <div style={{ color: "white", fontSize: 40 }}>+</div>
        )}
      </label>
      <input
        type='file'
        id={`fileInput${id}`}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default FileUpload;
