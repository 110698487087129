import React, { useState, useEffect } from "react";
import "../css/newBusinesspages.css";
import { useParams, Navigate, useLocation } from "react-router-dom";
import "../css/loginPage.css";
import { getAuth } from "firebase/auth";
import { firestore } from "../firebase";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  collection,
  runTransaction,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import Header from "../components/header";
import Footer from "../components/footer";
import SideMenu from "./components/sidemenu";
import MyLocations from "./components/myLocations";
import Subscription from "./components/subscription";
import Events from "./components/events";
import Profile from "./components/profile";
import WelcomePage from "./components/welcomePage";

const auth = getAuth();
const db = firestore;

const BusinessPages = (props) => {
  let { page } = useParams();
  const [loggedIn, setLoggedIn] = useState(true);
  const [userInfomation, setUserInfomation] = useState(null);
  const [inBusiness, setInbusiness] = useState(true);
  const [inBusinessID, setInbusinessID] = useState(true);
  const [newBusiness, setNewBusiness] = useState(false);
  const [newBusinessName, setNewBusinessName] = useState("");
  const [businessAdded, setBusinessAdded] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    document.title = "Mapli - MyBusiness";

    auth.onAuthStateChanged(async function (user) {
      if (user) {
        setLoggedIn(true);
        const userDoc = doc(db, "users", user.uid);

        const userInfo = await getDoc(userDoc);
        setUserInfomation(userInfo.data());
        if (userInfo.data().businesses !== undefined) {
          setInbusiness(userInfo.data().businessNames[0]);
          setInbusinessID(userInfo.data().businesses[0]);

          const usersRef = collection(db, "invites");
          const q = query(usersRef, where("token", "==", token));

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              if (userInfo.data().epost === doc.data().email) {
                updateDoc(userDoc, {
                  businesses: [doc.data().businessID],
                  businessNames: [doc.data().businessName],
                });
              }
            });
          }
        } else {
          if (token !== undefined) {
            console.log(token);
            const usersRef = collection(db, "invites");
            const q = query(usersRef, where("token", "==", token));
            console.log(token);

            const querySnapshot = await getDocs(q);
            console.log(querySnapshot);
            if (!querySnapshot.empty) {
              console.log(querySnapshot);
              querySnapshot.forEach((doc) => {
                if (userInfo.data().epost === doc.data().email) {
                  updateDoc(userDoc, {
                    businesses: [doc.data().businessID],
                    businessNames: [doc.data().businessName],
                  });
                }
              });
            } else {
              setInbusiness(false);
            }
          } else {
            setInbusiness(false);
          }
        }
      } else {
        setLoggedIn(false);
      }
    });
  }, [auth, businessAdded]);

  async function createCustomerNumber() {
    const customerNumberRef = doc(db, "settings", "customerNumber");

    return await runTransaction(db, async (transaction) => {
      const customerNumberDoc = await transaction.get(customerNumberRef);
      if (!customerNumberDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      const newCustomerNumber = customerNumberDoc.data().number + 1;
      transaction.update(customerNumberRef, { number: newCustomerNumber });
      return newCustomerNumber; // Return the new customer number
    })
      .then((newCustomerNumber) => {
        console.log("Customer number generated: ", newCustomerNumber);
        return newCustomerNumber; // Ensure this is returned for further processing
      })
      .catch((error) => {
        console.error("Transaction failed: ", error);
        throw error; // Proper error handling
      });
  }

  const onAddNewBusiness = async () => {
    const customerNumber = await createCustomerNumber();

    const userDoc = doc(db, "users", auth.currentUser.uid);

    if (newBusinessName.length > 0) {
      await addDoc(collection(db, "businesses"), {
        name: newBusinessName,
        userAccess: [auth.currentUser.uid],
        userAccessEmails: [userInfomation.epost], // Again assuming `email` is correct.
        menus: [],
        customerNumber: customerNumber,
        businessContactEmail: userInfomation.epost,
      }).then(
        async (x) =>
          await updateDoc(userDoc, {
            businesses: [x.id],
            businessNames: [newBusinessName],
          })
      );
      setBusinessAdded(true);
    }
  };

  return !loggedIn ? (
    <Navigate to='/login' replace />
  ) : inBusiness && inBusinessID ? (
    <>
      <Header />
      <div className='BusinessPagesMainContainer'>
        <SideMenu
          page={page}
          currentBusiness={inBusiness}
          user={userInfomation}
        />
        <div className='BusinessPagesContent'>
          {page === "myLocations" ? (
            <MyLocations currentBusinessID={inBusinessID} />
          ) : page === "subscription" ? (
            <Subscription currentBusinessID={inBusinessID} />
          ) : page === "events" ? (
            <Events currentBusinessID={inBusinessID} />
          ) : page === "profile" ? (
            <Profile currentBusinessID={inBusinessID} />
          ) : (
            <WelcomePage currentBusiness={inBusiness} />
          )}
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <div
      className='BusinessPagesMainContainer'
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        height: window.screen.height * 0.9,
      }}>
      <div className='editLocationPage'>
        {!newBusiness ? (
          <>
            <h1>
              Velkommen til <label className='locationNameText'>Mapli</label>
            </h1>
            <div className='aboutLocationEditting'>
              <label style={{ textAlign: "start", marginTop: "10px" }}>
                Før vi fortsetter må du være en del av en bedrift
              </label>
              <label style={{ textAlign: "start" }}>
                Er bedriften din allerede i{" "}
                <label className='locationNameText'>Mapli</label>?
              </label>
              <label style={{ textAlign: "start" }}>
                Da må du få redigeringstillatelse fra en kollega{" "}
                <a
                  href='#'
                  className='locationNameText'
                  style={{ textDecoration: "underline" }}>
                  les hvordan
                </a>
                .
              </label>
            </div>
            <div>
              <a
                onClick={() => {
                  setNewBusiness(true);
                }}
                href='#'
                className='universalButtonRed'
                style={{ marginLeft: "5px" }}>
                Opprett ny bedrift
              </a>
            </div>
            <a
              href='#'
              onClick={() => {
                auth.signOut();
              }}
              style={{ marginLeft: "5px" }}>
              Logg ut
            </a>
          </>
        ) : (
          <>
            <h1>
              Opprett ny <label className='locationNameText'>Mapli</label>{" "}
              bedrift
            </h1>
            <div className='aboutLocationEditting'>
              <label style={{ textAlign: "start", marginTop: "10px" }}>
                Navn på din bedrift:
              </label>
              <input
                style={{ marginTop: "5px", border: "1px solid black" }}
                placeholder='Bedriftens navn'
                onChange={(x) => {
                  setNewBusinessName(x.target.value);
                }}
              />
              <label style={{ textAlign: "start", marginTop: "10px" }}>
                Er bedriften din allerede i{" "}
                <label className='locationNameText'>Mapli</label>?
              </label>
              <label style={{ textAlign: "start" }}>
                Da må du få redigeringstillatelse fra en kollega{" "}
                <a
                  href='#'
                  className='locationNameText'
                  style={{ textDecoration: "underline" }}>
                  les hvordan
                </a>
                .
              </label>
            </div>
            <div>
              <a
                onClick={() => onAddNewBusiness()}
                href='#'
                className='universalButtonRed'
                style={{ marginLeft: "5px" }}>
                Opprett ny bedrift
              </a>
              <a
                onClick={() => (setNewBusiness(false), setNewBusinessName(""))}
                href='#'
                className='universalButton'
                style={{ marginLeft: "5px" }}>
                Tilbake
              </a>
            </div>
            <label
              style={{
                display: businessAdded ? "block" : "none",
                color: "#52c78c",
              }}>
              {newBusinessName} opprettet!
            </label>
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessPages;
