import React, { useState, useEffect } from "react";
import "../css/menuWidget.css";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";

const db = firestore;

const MenuWidget = (props) => {
  const [menulist, setMenuList] = useState(null);
  const [locationImage, setLocationImage] = useState(null);
  let { menu } = useParams();

  useEffect(() => {
    console.log(menu);
    const onGetMenu = async () => {
      const getMenu = doc(db, "lokasjoner", menu);
      const locationInfo = await getDoc(getMenu);
      setLocationImage(locationInfo.data().mainProfileImage);
      setMenuList({
        menuNorwegian: locationInfo.data().menus,
        menuEnglish: locationInfo.data().menuListLang
          ? locationInfo.data().menuListLang.en_US
            ? locationInfo.data().menuListLang.en_US
            : []
          : [],
      });
    };
    if (menu !== undefined) {
      onGetMenu();
    }
    console.log(menu);
  }, [menu]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", margin: 20 }}>
        {locationImage !== null ? (
          <img src={locationImage} width='125px' />
        ) : (
          <></>
        )}
      </div>
      <div className='menu-container'>
        {menulist !== null ? (
          menulist.menuNorwegian.map((item, itemIndex) => (
            <div key={itemIndex} className='menu-category'>
              <h3 className='category-title'>{item.category}</h3>{" "}
              {/* Display the category as a heading */}
              {item.menuItems.map((menuItem, menuItemIndex) => (
                <div key={menuItemIndex} className='menu-item'>
                  <div className='menu-item-header'>
                    <h4 className='menu-item-name'>{menuItem}</h4>{" "}
                    {/* Display the menu item name */}
                    <p className='menu-item-price'>
                      {item.prices[menuItemIndex]} NOK
                    </p>{" "}
                    {/* Display the corresponding price */}
                  </div>
                  <p className='menu-item-description'>
                    {item.descriptions[menuItemIndex]}
                  </p>{" "}
                  {/* Display the corresponding description */}
                  <p className='menu-item-allergens'>
                    Allergener: {item.alergener[menuItemIndex]}
                  </p>{" "}
                  {/* Display the corresponding allergens */}
                </div>
              ))}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MenuWidget;
