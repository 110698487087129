import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import ReactLoading from "react-loading";
import Stripe from "stripe";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { BsFillQuestionCircleFill } from "react-icons/bs";

import FileUpload from "./editLocationComponents/fileUpload";
import { firestore } from "../../firebase";

const stripe = Stripe(
  "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"
  //oppdatert til prod!
);

const db = firestore;
const storage = getStorage();

const EditEvent = ({ currentBusinessID }) => {
  const [eventInformation, setEventInformation] = useState({
    aboutBusiness: "",
    aboutEvent: "",
    active: false,
    averagePrice: "",
    paymentPrice: 0,
    endDate: 0,
    eventDate: 0,
    eventTime: null,
    location: null,
    locationName: "",
    startDate: 0,
    tags: [],
    tagsLang: {
      en_US: [],
      nb_NO: [],
    },
    tags_enUS: [],
    aboutBusiness_enUS: "",
    aboutEvent_enUS: "",
    locationTags: [],
    locationTags_enUS: [],
  });
  const [missingFields, setMissingFields] = useState({
    name: false,
    location: false,
    startDate: false,
    endDate: false,
  });
  const [changingLocation, setChangingLocation] = useState(false);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [eventLocation, setEventLocation] = useState(null);
  const [fileImage, setFileImage] = useState({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
  });
  const [fileName, setFileName] = useState({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
  });
  const [openEditPictureModal, setOpenEditPictureModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [newProfileImage, setNewProfileImage] = useState("");
  const [newProfileImagePreview, setNewProfileImagePreview] = useState("");
  const [newProfileFile, setNewProfileFile] = useState(null);
  const [locationInformation, setLocationinformation] = useState(null);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingPaymentDone, setLoadingPaymentDone] = useState(false);
  const [paymentSuccessFull, setPaymentSuccessFull] = useState(false);
  const [selectedEditMenu, setSelectedEditMenu] = useState("about");
  const [editLanguage, setEditLanguage] = useState("norwegian");
  const [freeDays, setFreedays] = useState(0);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);

  let { edit } = useParams();

  useEffect(() => {
    const getEventInformation = async () => {
      const getEvent = doc(db, "events", edit);
      const eventInfo = await getDoc(getEvent);

      const userInfo = doc(db, "businesses", currentBusinessID);
      const userInformation = await getDoc(userInfo);
      const userData = userInformation.data();
      const menus = userData.menus;

      setFreedays(userData.freeEventDays ?? 0);

      setLocationinformation(eventInfo.data());
      if (eventInfo.data().profileImages) {
        eventInfo.data().profileImages.forEach((x, index) => {
          const fileName = `file${index + 1}`; // This will create "file1", "file2", etc.
          setFileImage((prevData) => ({
            ...prevData,
            [fileName]: x, // Dynamically assign the property name and value
          }));
        });
      }

      let activeLocations = [];

      for (const menu of menus) {
        // Assuming each menu has an identifier to get the specific locations collection
        const locationsRef = doc(db, "lokasjoner", menu);
        const querySnapshot = await getDoc(locationsRef);

        if (querySnapshot.data().active === true) {
          activeLocations.push(querySnapshot);
        }
      }
      setBusinessLocations(activeLocations);

      const getOrganiser = doc(
        db,
        "lokasjoner",
        eventInfo.data().eventOrganiser
      );
      const organiser = await getDoc(getOrganiser);

      setEventInformation({
        aboutBusiness: eventInfo.data().aboutBusiness,
        aboutEvent: eventInfo.data().aboutEvent,
        active: eventInfo.data().active,
        averagePrice: eventInfo.data().averagePrice,
        paymentPrice: eventInfo.data().paymentPrice,
        endDate: eventInfo.data().endDate,
        eventDate: eventInfo.data().eventDate,
        eventTime: eventInfo.data().eventTime,
        location: organiser.data(),
        eventOrganiser: eventInfo.data().eventOrganiser,
        locationName: eventInfo.data().locationName,
        startDate: eventInfo.data().startDate,
        tags: eventInfo.data().tags,
        tagsLang: eventInfo.data().tagsLang,
        tags_enUS: eventInfo.data().tagsLang
          ? eventInfo.data().tagsLang.en_US
            ? eventInfo.data().tagsLang.en_US
            : []
          : [],
        aboutBusiness_enUS: eventInfo.data().aboutBusinessLang
          ? eventInfo.data().aboutBusinessLang.en_US
            ? eventInfo.data().aboutBusinessLang.en_US
            : ""
          : "",
        aboutEvent_enUS: eventInfo.data().aboutEventLang
          ? eventInfo.data().aboutEventLang.en_US
            ? eventInfo.data().aboutEventLang.en_US
            : ""
          : "",
        locationTags: eventInfo.data().locationTags
          ? eventInfo.data().locationTags
          : [],
        locationTags_enUS: eventInfo.data().locationTagsLang
          ? eventInfo.data().locationTagsLang.en_US
            ? eventInfo.data().locationTagsLang.en_US
            : []
          : [],
      });

      setNewProfileImage(eventInfo.data().mainProfileImage);
    };
    const isValidID =
      currentBusinessID &&
      typeof currentBusinessID === "string" &&
      currentBusinessID.length > 0;

    if (isValidID) {
      getEventInformation();
    } else {
      console.log("Invalid business ID. Please check the ID or sign in.");
    }
  }, [edit, currentBusinessID]);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Adding leading zero
    const day = String(d.getDate()).padStart(2, "0"); // Adding leading zero
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (eventInformation.startDate !== 0 && eventInformation.endDate !== 0) {
      if (eventInformation.endDate > eventInformation.startDate) {
        let newPrice = 0;
        newPrice = eventInformation.endDate - eventInformation.startDate;
        let newPriceInDays = newPrice / (3600 * 24);

        if (freeDays > 0) {
          if (newPriceInDays - freeDays > 0) {
            newPriceInDays = newPriceInDays - freeDays;
          } else {
            newPriceInDays = 0;
          }
        }

        setEventInformation((prevItems) => ({
          ...prevItems,
          paymentPrice: newPriceInDays * 25,
        }));
      } else {
        setEventInformation((prevItems) => ({
          ...prevItems,
          paymentPrice: 0,
        }));
      }
    }
  }, [eventInformation.startDate, eventInformation.endDate]);

  const onSetLocation = (event) => {
    setEventInformation((prevItems) => ({
      ...prevItems,
      location: event.data(),
    }));
    setEventLocation(event.id);
    setChangingLocation(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setNewProfileFile(file);
      setNewProfileImagePreview(URL.createObjectURL(file));
    }
  };

  const onSaveChanges = async () => {
    if (
      fileImage.file1.length > 0 ||
      fileImage.file2.length > 0 ||
      fileImage.file3.length > 0 ||
      fileImage.file4.length > 0 ||
      locationInformation.profileImages !== undefined
    ) {
      let profileImages = ["", "", "", ""];
      if (locationInformation.profileImages !== undefined) {
        profileImages = locationInformation.profileImages;
      }
      profileImages.forEach((x, index) => {
        const file = `file${index + 1}`; // This will create "file1", "file2", etc.
        if (fileImage[file] !== x && fileName[file] !== "") {
          const uniqueKey = uuidv4();

          const profileImageArray = locationInformation.profileImages
            ? locationInformation.profileImages
            : ["", "", "", ""];

          const storageRef = ref(storage, `images/${uniqueKey}`);

          uploadBytes(storageRef, fileName[file]).then((snapshot) => {
            console.log("Uploaded a blob or file!");

            // Get the download URL
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);

              profileImageArray[index] = downloadURL;

              // Save the download URL to Firestore
              const collectionRef = doc(db, "events", edit);
              updateDoc(collectionRef, { profileImages: profileImageArray })
                .then((docRef) => {
                  console.log("Document written with ID: ", docRef.id);
                })
                .catch((error) => {
                  console.error("Error adding document: ", error);
                });
            });
          });
        }
      });
    }

    // Save main profileImage

    if (
      newProfileImage !== locationInformation.mainProfileImage &&
      newProfileFile
    ) {
      const uniqueKey = uuidv4();
      const storage = getStorage();
      const storageRef = ref(storage, `images/${uniqueKey}`);

      uploadBytes(storageRef, newProfileFile).then((snapshot) => {
        console.log("Uploaded a blob or file!");

        // Get the download URL
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);

          // Save the download URL to Firestore
          const collectionRef = doc(db, "events", edit);
          updateDoc(collectionRef, { mainProfileImage: downloadURL })
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        });
      });
    }

    const collectionRef = doc(db, "events", edit);
    await updateDoc(collectionRef, {
      aboutBusiness: eventInformation.aboutBusiness,
      aboutEvent: eventInformation.aboutEvent,
      active: eventInformation.active,
      paymentPrice: eventInformation.paymentPrice,
      averagePrice: eventInformation.averagePrice,
      endDate: eventInformation.endDate,
      eventDate: eventInformation.eventDate,
      eventTime:
        eventInformation.eventTime !== undefined
          ? eventInformation.eventTime
          : null,
      eventOrganiser:
        eventLocation === null
          ? eventInformation.eventOrganiser
          : eventLocation,
      locationName: eventInformation.locationName,
      startDate: eventInformation.startDate,
      tags: eventInformation.tags,
      tagsLang: {
        en_US: eventInformation.tags_enUS,
        nb_NO: eventInformation.tags,
      },
      aboutBusinessLang: {
        en_US: eventInformation.aboutBusiness_enUS,
        nb_NO: eventInformation.aboutBusiness,
      },
      aboutEventLang: {
        en_US: eventInformation.aboutEvent_enUS,
        nb_NO: eventInformation.aboutEvent,
      },
      locationTagsLang: {
        en_US: eventInformation.locationTags_enUS,
        nb_NO: eventInformation.locationTags,
      },
      locationTags: eventInformation.locationTags,
    });
    if (eventInformation.active) {
      setSavedChanges(true);
    }
  };

  const onPayForEvent = async () => {
    const userDoc = doc(db, "businesses", currentBusinessID);
    const userInfo = await getDoc(userDoc);

    let newPrice = 0;
    newPrice = eventInformation.endDate - eventInformation.startDate;
    let newPriceInDays = newPrice / (3600 * 24);

    let stripeID = userInfo.data().stripeID ? userInfo.data().stripeID : null;

    const numDays = eventInformation.paymentPrice / 25;
    try {
      const invoiceItem = await stripe.invoiceItems.create({
        customer: stripeID,
        price: "price_1PiwuvF698POdFrN4InEsWSi", //oppdatert til prod!
        quantity: numDays,
      });

      const invoice = await stripe.invoices.create({
        customer: stripeID,
        auto_advance: true, // Automatically finalize the invoice
      });

      // Pay the invoice immediately
      const paidInvoice = await stripe.invoices.pay(invoice.id);

      // Check the invoice payment status
      if (paidInvoice.status === "paid") {
        console.log("Payment was successful!");
        setPaymentSuccessFull(true);
        const collectionRef = doc(db, "events", edit);

        await updateDoc(collectionRef, {
          active: true,
        });

        await updateDoc(userDoc, {
          freeEventDays: userInfo.freeEventDays - (freeDays - newPriceInDays),
        });
      } else {
        setPaymentSuccessFull(false);
        console.log(`Payment failed with status: ${paidInvoice.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoadingPaymentDone(true);
  };

  const onTranslateText = async (x) => {
    if (x === "aboutBusiness") {
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/${x}/${eventInformation.aboutBusiness}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setEventInformation({
            ...eventInformation,
            aboutBusiness_enUS: data.choices[0].message.content,
          });
        });
    } else if (x === "aboutEvent") {
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/${x}/${eventInformation.aboutEvent}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setEventInformation({
            ...eventInformation,
            aboutEvent_enUS: data.choices[0].message.content,
          });
        });
    } else if (x === "tags") {
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/${x}/${eventInformation.tags}`
      )
        .then((res) => res.json())
        .then((data) => {
          const translationArray = data.choices[0].message.content
            .split(",")
            .map((word) => word.trim());
          console.log(translationArray);
          setEventInformation({
            ...eventInformation,
            tags_enUS: translationArray,
          });
        });
    } else if (x === "maintags") {
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/tags/${eventInformation.locationTags}`
      )
        .then((res) => res.json())
        .then((data) => {
          const translationArray = data.choices[0].message.content
            .split(",")
            .map((word) => word.trim());
          console.log(translationArray);
          setEventInformation({
            ...eventInformation,
            locationTags_enUS: translationArray,
          });
        });
    }
  };

  return eventInformation.location === null ? (
    <></>
  ) : changingLocation ? (
    <>
      <div>
        <h1>Hvilken lokasjon gjelder arragementet for?</h1>
        {businessLocations.length > 0 ? (
          businessLocations.map((lokasjonsInformasjon, rowIndex) => {
            return (
              <div
                key={rowIndex}
                className='box'
                style={{ marginBottom: "15px" }}>
                <div className='businessOverView'>
                  <label className='locationNameLabel'>
                    {lokasjonsInformasjon.data()?.locationName}
                  </label>
                  <label>
                    {lokasjonsInformasjon.data()?.adress
                      ? lokasjonsInformasjon.data()?.adress
                      : "-"}
                  </label>
                  <div>
                    <a
                      onClick={() => {
                        onSetLocation(lokasjonsInformasjon);
                      }}
                      className='universalButtonRed'
                      style={{ marginLeft: "5px" }}>
                      Velg
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p style={{ textAlign: "center", marginTop: 0 }}>
            Ingen lokasjoner å vise til
          </p>
        )}
      </div>
    </>
  ) : (
    <>
      <div>
        <h1>Redigerer {eventInformation.locationName}</h1>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <button
              onClick={() => {
                if (editLanguage !== "english") {
                  setEditLanguage("english");
                } else {
                  setEditLanguage("norwegian");
                }
              }}
              className='editMenuButtons'
              style={
                editLanguage === "english"
                  ? {
                      color: "#f9615b",
                      backgroundColor: "white",
                      border: "white",
                    }
                  : {}
              }>
              Engelsk
            </button>
          </div>
        </div>
        <div
          className='box'
          style={{
            borderTopLeftRadius: 0,
            padding: 25,
            paddingRight: 35,
            marginBottom: "50px",
          }}>
          {/* Top */}
          <div className='editAboutTop'>
            {/* Picture */}
            {editLanguage !== "english" ? (
              <div className='editAboutPictureContainer'>
                <div className='editPicture'>
                  {newProfileImage && newProfileImage.length > 1 ? (
                    <img
                      src={newProfileImage}
                      alt='Preview'
                      className='editPicture'
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <a
                  className='editPageLink'
                  href='#'
                  onClick={() => {
                    setOpenEditPictureModal(true);
                  }}>
                  Rediger bilde
                </a>
              </div>
            ) : (
              <></>
            )}
            {/* Name + Tags */}
            <div className='editAboutNameTagsContainer'>
              {editLanguage !== "english" ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className='editPageLabel'>Navn</label>
                  <input
                    placeholder='Navn'
                    value={eventInformation.locationName}
                    onChange={(x) => {
                      setEventInformation((prevItems) => ({
                        ...prevItems,
                        locationName: x.target.value,
                      }));
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "15px",
                    width: "58%",
                  }}>
                  <label className='editPageLabel'>
                    {editLanguage !== "english" ? (
                      "Tags"
                    ) : (
                      <>
                        Tags (engelske tags){" "}
                        <a
                          href='#'
                          class='editPageLink'
                          onClick={() => {
                            onTranslateText("tags");
                          }}>
                          Oversett automatisk
                        </a>
                      </>
                    )}
                  </label>
                  <input
                    placeholder='Søkeord for lokasjonen (eg. burger, asiatisk, konserter etc.)'
                    value={
                      editLanguage !== "english"
                        ? eventInformation.tags.join(",")
                        : eventInformation.tags_enUS.join(",")
                    }
                    onChange={(e) => {
                      if (editLanguage !== "english") {
                        // Get the current input value directly
                        const inputValue = e.target.value;

                        // Split the input based on commas without altering spaces
                        const newTags = inputValue.split(",").map((tag) => {
                          const trimmedTag = tag.trim();
                          return (
                            trimmedTag.charAt(0).toUpperCase() +
                            trimmedTag.slice(1).toLowerCase()
                          );
                        });

                        // Update the state with these new tags without trimming spaces
                        setEventInformation({
                          ...eventInformation,
                          tags: newTags,
                        });
                      } else {
                        // Get the current input value directly
                        const inputValue = e.target.value;

                        // Split the input based on commas without altering spaces
                        const newTags = inputValue.split(",").map((tag) => {
                          const trimmedTag = tag.trim();
                          return (
                            trimmedTag.charAt(0).toUpperCase() +
                            trimmedTag.slice(1).toLowerCase()
                          );
                        });

                        // Update the state with these new tags without trimming spaces
                        setEventInformation({
                          ...eventInformation,
                          tags_enUS: newTags,
                        });
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                    marginTop: "15px",
                  }}>
                  <label className='editPageLabel'>
                    {editLanguage !== "english" ? (
                      "Hoved tags"
                    ) : (
                      <>
                        Hoved tags (engelske tags){" "}
                        <a
                          href='#'
                          class='editPageLink'
                          onClick={() => {
                            onTranslateText("maintags");
                          }}>
                          Oversett automatisk
                        </a>
                      </>
                    )}{" "}
                    <a href='/faq#tags'>
                      <BsFillQuestionCircleFill color='#4550e2' size='17px' />
                    </a>
                  </label>
                  <input
                    placeholder='Synlige tags på profilen (eg. italiensk, bar, etc.)'
                    value={
                      editLanguage !== "english"
                        ? eventInformation.locationTags.join(",")
                        : eventInformation.locationTags_enUS.join(",")
                    }
                    onChange={(e) => {
                      if (editLanguage !== "english") {
                        // Get the current input value directly
                        const inputValue = e.target.value;

                        // Split the input based on commas without altering spaces
                        const newTags = inputValue.split(",");

                        // Update the state with these new tags without trimming spaces
                        setEventInformation({
                          ...eventInformation,
                          locationTags: newTags,
                        });
                      } else {
                        // Get the current input value directly
                        const inputValue = e.target.value;

                        // Split the input based on commas without altering spaces
                        const newTags = inputValue
                          .split(",")
                          .map((tag) => tag.trim());

                        // Update the state with these new tags without trimming spaces
                        setEventInformation({
                          ...eventInformation,
                          locationTags_enUS: newTags,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end topp!! */}
          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}>
                <label className='editPageLabel'>Dato for arrangementet</label>
                <input
                  style={{ paddingRight: "10px" }}
                  type='date'
                  value={formatDate(eventInformation.eventDate * 1000)}
                  onChange={(x) => {
                    setEventInformation((prevItems) => ({
                      ...prevItems,
                      eventDate: new Date(x.target.value).getTime() / 1000,
                    }));
                  }}
                />
                <label
                  style={{
                    display: missingFields.name ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe!
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}>
                <label className='editPageLabel'>
                  Tidspunkt for arrangementet
                </label>
                <input
                  className='timepicker'
                  type='time'
                  style={{ width: "100%" }}
                  value={
                    eventInformation.eventTime
                      ? `${String(eventInformation.eventTime.hour).padStart(
                          2,
                          "0"
                        )}:${String(
                          eventInformation.eventTime.minutes
                        ).padStart(2, "0")}`
                      : ""
                  }
                  onChange={(x) => {
                    if (x.target.value === "") {
                      setEventInformation((prevState) => ({
                        ...prevState,
                        eventTime: null,
                      }));
                    } else {
                      const [hrs, mins] = x.target.value.split(":").map(Number);
                      setEventInformation((prevState) => ({
                        ...prevState,
                        eventTime: {
                          // Ensure hours and minutes are stored as numbers
                          hour: hrs || 0, // Fallback to 0 if hrs is NaN
                          minutes: mins || 0, // Fallback to 0 if mins is NaN
                        },
                      }));
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}>
                <label className='editPageLabel'>Billettpris</label>
                <input
                  style={{ paddingRight: "10px" }}
                  placeholder='Billettpris'
                  type='number'
                  value={eventInformation.averagePrice}
                  onChange={(x) => {
                    setEventInformation((prevItems) => ({
                      ...prevItems,
                      averagePrice: Number(x.target.value),
                    }));
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}>
                <label className='editPageLabel'>
                  Hvilken lokasjon gjelder arragementet for?
                </label>
                <input
                  placeholder='Lokasjon'
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => {
                    setChangingLocation(true);
                  }}
                  value={
                    eventInformation.location !== null
                      ? eventInformation.location.adress !== undefined
                        ? eventInformation.location.locationName +
                          " - " +
                          eventInformation.location.adress
                        : eventInformation.location.locationName
                      : ""
                  }
                />
                <label
                  style={{
                    display: missingFields.location ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe!
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
            }}>
            <label className='editPageLabel'>
              {editLanguage !== "english" ? (
                "Skriv en kort beskrivende tekst om arrangementet (max 70 ord)"
              ) : (
                <>
                  Skriv en kort beskrivende tekst om arrangementet (max 70 ord)
                  (engelsk beskrivelse){" "}
                  <a
                    href='#'
                    class='editPageLink'
                    onClick={() => {
                      onTranslateText("aboutBusiness");
                    }}>
                    Oversett automatisk
                  </a>
                </>
              )}
            </label>
            <textarea
              placeholder='Om arrangementet (max 70 ord)'
              value={
                editLanguage !== "english"
                  ? eventInformation.aboutBusiness
                  : eventInformation.aboutBusiness_enUS
              }
              onChange={(x) => {
                if (editLanguage !== "english") {
                  setEventInformation({
                    ...eventInformation,
                    aboutBusiness: x.target.value,
                  });
                } else {
                  setEventInformation({
                    ...eventInformation,
                    aboutBusiness_enUS: x.target.value,
                  });
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
            }}>
            <label className='editPageLabel'>
              {editLanguage !== "english" ? (
                "Skriv litt om hvordan man kan kjøpe billetter til arrangementet (her kan linker inkluderes)"
              ) : (
                <>
                  Skriv litt om hvordan man kan kjøpe billetter til
                  arrangementet (her kan linker inkluderes) (engelsk
                  informasjon){" "}
                  <a
                    href='#'
                    class='editPageLink'
                    onClick={() => {
                      onTranslateText("aboutEvent");
                    }}>
                    Oversett automatisk
                  </a>
                </>
              )}
            </label>
            <textarea
              placeholder='Informasjon om hvordan en kan kjøpe billetter, pris, aldersgrense, når åpner dørene, etc.'
              value={
                editLanguage !== "english"
                  ? eventInformation.aboutEvent
                  : eventInformation.aboutEvent_enUS
              }
              onChange={(x) => {
                if (editLanguage !== "english") {
                  setEventInformation({
                    ...eventInformation,
                    aboutEvent: x.target.value,
                  });
                } else {
                  setEventInformation({
                    ...eventInformation,
                    aboutEvent_enUS: x.target.value,
                  });
                }
              }}
            />
          </div>
          {editLanguage !== "english" ? (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}>
                <label className='editPageLabel'>Annonse start</label>
                <input
                  disabled={eventInformation.active}
                  placeholder='-- -- --'
                  type='date'
                  style={{ paddingRight: "10px" }}
                  value={formatDate(eventInformation.startDate * 1000)}
                  onChange={(x) => {
                    setEventInformation((prevItems) => ({
                      ...prevItems,
                      startDate: new Date(x.target.value).getTime() / 1000,
                    }));
                  }}
                />
                <label
                  style={{
                    display: missingFields.startDate ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe!
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}>
                <label className='editPageLabel'>Annonse slutt</label>
                <input
                  disabled={eventInformation.active}
                  placeholder='-- -- --'
                  type='date'
                  style={{ paddingRight: "10px" }}
                  value={formatDate(eventInformation.endDate * 1000)}
                  onChange={(x) => {
                    setEventInformation((prevItems) => ({
                      ...prevItems,
                      endDate: new Date(x.target.value).getTime() / 1000,
                    }));
                  }}
                />
                <label
                  style={{
                    display: missingFields.endDate ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe! Datoen må være etter startdato.
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}>
                <label className='editPageLabel'>Pris</label>
                <input
                  disabled
                  value={eventInformation.paymentPrice + " NOK"}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}>
              <label className='editPageLabel'>
                Last opp bilder av lokasjonen (max 4)
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                }}>
                <div style={{ display: "flex" }}>
                  <FileUpload
                    id='file1'
                    fileImage={fileImage}
                    setFileImage={setFileImage}
                    setFileName={setFileName}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <FileUpload
                      id='file2'
                      fileImage={fileImage}
                      setFileImage={setFileImage}
                      setFileName={setFileName}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <FileUpload
                    id='file3'
                    fileImage={fileImage}
                    setFileImage={setFileImage}
                    setFileName={setFileName}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <FileUpload
                      id='file4'
                      fileImage={fileImage}
                      setFileImage={setFileImage}
                      setFileName={setFileName}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div>
            {eventInformation.active ? (
              <button
                onClick={() => setOpenSaveChangesModal(true)}
                className='universalButtonRed'
                style={{
                  width: "150px",
                  marginTop: "25px",
                  marginRight: "10px",
                }}>
                Lagre endringer
              </button>
            ) : (
              <button
                onClick={() => (onSaveChanges(), setOpenPublishModal(true))}
                className='universalButtonRed'
                style={{
                  width: "150px",
                  marginTop: "25px",
                  marginRight: "10px",
                }}>
                Gå videre
              </button>
            )}
            <button
              style={{
                width: "150px",
                marginTop: "25px",
              }}
              onClick={() => {}}
              className='universalButton'>
              Avbryt
            </button>
          </div>
        </div>
      </div>
      {/* Modals */}
      <Modal
        isOpen={openEditPictureModal}
        onRequestClose={() => (
          setOpenEditPictureModal(false), setNewProfileImage("")
        )}
        className='modalStyle'
        style={{
          content: {
            width: "17%",
          },
        }}>
        <div className='editActiveModal'>
          <label className='editPageLabel'>Endre lokasjonens hovedbilde</label>
          <div className='editActiveModalButtonContainer'>
            <input type='file' onChange={handleImageChange} />
            {newProfileImagePreview && (
              <img
                src={newProfileImagePreview}
                alt='Preview'
                height='125px'
                width='125px'
              />
            )}
            <button
              onClick={() => (
                setOpenEditPictureModal(false),
                setNewProfileImage(newProfileImagePreview)
              )}
              className='universalButtonRed'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Bruk bilde
            </button>
            <button
              onClick={() => (
                setOpenEditPictureModal(false), setNewProfileImagePreview("")
              )}
              className='universalButton'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Avbryt
            </button>
          </div>
        </div>
      </Modal>
      {/* Save Changes Modal */}
      <Modal
        isOpen={openSaveChangesModal}
        onRequestClose={() => (
          setOpenSaveChangesModal(false), setSavedChanges(false)
        )}
        className='modalStyle'
        style={{
          content: {
            width: "20%",
          },
        }}>
        <div className='editActiveModal'>
          <label className='editPageLabel'>
            Lagerer og publiserer endringer! Er du sikker på at du vil lagre og
            publisere endringene for offentligheten?
          </label>
          <div className='editActiveModalButtonContainer'>
            <div
              style={{
                display: savedChanges ? "block" : "none",
                color: "#52c78c",
                marginBottom: "5px",
              }}>
              Endringer lagret!
            </div>
            <button
              onClick={onSaveChanges}
              className='universalButtonRed'
              style={{
                width: "240px",
                margin: 0,
              }}>
              Ja, lagre og publiser endringer
            </button>
            <button
              onClick={() => (
                setOpenSaveChangesModal(false), setSavedChanges(false)
              )}
              className='universalButton'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Nei, ta meg tilbake
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openPublishModal}
        onRequestClose={() => (
          setOpenPublishModal(false),
          setLoadingPayment(false),
          setLoadingPaymentDone(false),
          setPaymentSuccessFull(false)
        )}
        className='modalStyle'
        style={{
          content: {
            width: "35%",
            height: "35%",
            position: "relative",
          },
        }}>
        <div className='editActiveModal'>
          {paymentSuccessFull && loadingPaymentDone ? (
            <>
              <label className='editPageLabel'>Betalingsmetode godkjent!</label>
              <label>
                Betalingen er lagt til neste trekk på ditt abonnement
              </label>

              <div>
                <IoIosCheckmarkCircle size={"30%"} color='#52c78c' />
              </div>
              <div
                style={
                  {
                    // position: "absolute",
                    // bottom: 0,
                  }
                }>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: loadingPayment ? "50px" : "",
                  }}>
                  <a
                    href='/MyBusiness/events/'
                    className='universalButtonRed'
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    Til annonsemeny
                  </a>
                </div>
              </div>
            </>
          ) : !paymentSuccessFull && loadingPaymentDone ? (
            <>
              <label className='editPageLabel'>Betalingsmetode feilet!</label>
              <label>Gå tilbake og forsøk igjen!</label>

              <div>
                <IoIosCloseCircle size={"30%"} color='#f9615b' />
              </div>
              <div
                style={
                  {
                    // position: "absolute",
                    // bottom: 0,
                  }
                }>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: loadingPayment ? "50px" : "",
                  }}>
                  <button
                    onClick={() => (
                      setOpenPublishModal(false),
                      setLoadingPayment(false),
                      setLoadingPaymentDone(false),
                      setPaymentSuccessFull(false)
                    )}
                    className='universalButtonRed'
                    style={{
                      width: "50%",
                    }}>
                    Tilbake til annonse
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <label className='editPageLabel'>Publiser annonse</label>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingRight: "15px",
                  }}>
                  <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    Annonsen starter:
                  </label>
                  <input
                    style={{
                      width: "70%",
                      textAlign: "center",
                      paddingRight: 15,
                    }}
                    disabled
                    value={formatDate(eventInformation.startDate * 1000)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "15px",
                  }}>
                  <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    Annonsen slutter:
                  </label>
                  <input
                    style={{
                      width: "70%",
                      textAlign: "center",
                      paddingRight: 15,
                    }}
                    disabled
                    value={formatDate(eventInformation.endDate * 1000)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  Pris:
                </label>
                <input
                  style={{
                    width: "20%",
                    textAlign: "center",
                    paddingRight: 15,
                  }}
                  disabled
                  value={eventInformation.paymentPrice}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                }}>
                <label>
                  Ved å publisere en annonse godkjenner du vilkårene for{" "}
                  <a href='#'>kjøp av annonseplass</a>
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: loadingPayment ? "50px" : "",
                  }}>
                  {loadingPayment ? (
                    <ReactLoading
                      color={"#f9615b"}
                      height={"10px"}
                      width={"50px"}
                    />
                  ) : (
                    <>
                      <button
                        onClick={() => (
                          setLoadingPayment(true), onPayForEvent()
                        )}
                        className='universalButtonRed'
                        style={{
                          width: "45%",
                        }}>
                        Betal og publiser
                      </button>
                      <button
                        onClick={() => setOpenPublishModal(false)}
                        className='universalButton'
                        style={{
                          width: "45%",
                        }}>
                        Avbryt
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EditEvent;
