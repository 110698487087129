import React, { useEffect } from "react";

const HubSpotForm = () => {
  useEffect(() => {
    // Create script element to load the external HubSpot script
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Once the script is loaded, initialize the form
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "144515868",
          formId: "34decb75-5d1a-4984-95f0-00e12a7d54ee",
          target: "#hubspotForm", // Specify the target div where the form will be rendered
        });
      }
    };

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id='hubspotForm'></div>; // This div will hold the HubSpot form
};

export default HubSpotForm;
