import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import * as geofirestore from "geofirestore";

const firebaseConfig = {
  apiKey: "AIzaSyDR8hMvFGD3kHX2W1M0Pe4gNSsjMU-T4Rk",
  authDomain: "menuapp-4c413.firebaseapp.com",
  projectId: "menuapp-4c413",
  storageBucket: "menuapp-4c413.appspot.com",
  messagingSenderId: "609436138667",
  appId: "1:609436138667:web:b4aff9eb1efad76c7b0bee",
  measurementId: "G-7DLCLVX562",
};

firebase.initializeApp({
  apiKey: "AIzaSyDR8hMvFGD3kHX2W1M0Pe4gNSsjMU-T4Rk",
  authDomain: "menuapp-4c413.firebaseapp.com",
  projectId: "menuapp-4c413",
  storageBucket: "menuapp-4c413.appspot.com",
  messagingSenderId: "609436138667",
  appId: "1:609436138667:web:b4aff9eb1efad76c7b0bee",
  measurementId: "G-7DLCLVX562",
});

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firestore2 = firebase.firestore();
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const GeoFirestore = geofirestore.initializeApp(firestore2);
