import React, { useState, useEffect } from "react";
import "../css/loginPage.css";
import { Link, useParams, Navigate, useLocation } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import Header from "../components/header";
import Footer from "../components/footer";

const auth = getAuth();
const db = firestore;

const LoginPage = (props) => {
  let { page } = useParams();
  const [loggedIn, setLoggedIn] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [tokenData, setTokenData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    const verifyToken = async () => {
      const usersRef = collection(db, "invites");
      const q = query(usersRef, where("token", "==", token));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          setTokenData(doc.data());
        });
      }
    };
    if (token) {
      verifyToken();
    }
  }, [token]);

  useEffect(() => {
    document.title = "Mapli - Login";
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });
  }, [auth]);

  const onLogin = (e) => {
    e.preventDefault();
    console.log(emailInput);
    console.log(passwordInput);
    signInWithEmailAndPassword(auth, emailInput, passwordInput)
      .then((userCredential) => {
        // Signed in
        const userDoc = doc(db, "users", userCredential.user.uid);

        if (token && emailInput === tokenData.email) {
          updateDoc(userDoc, {
            businesses: [tokenData.businessID],
            businessNames: [tokenData.businessName],
          });
        }

        const user = userCredential;
        console.log(user);
        console.log(user.user.uid);
        console.log(user.user.email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  };

  const onNewAccount = async (e) => {
    e.preventDefault();

    // const customerNumber = await createCustomerNumber();

    createUserWithEmailAndPassword(auth, emailInput, passwordInput)
      .then((userCredential) => {
        // Signed in

        const user = userCredential;
        console.log(user);
        console.log(user.user.uid);
        console.log(user.user.email);

        setDoc(doc(db, "users", user.user.uid), {
          epost: user.user.email,
          admin: false,
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  };

  const onForgotPassword = (e) => {
    e.preventDefault();

    console.log(emailInput);
    console.log(passwordInput);
    // signInWithEmailAndPassword(auth, "steffen@flyteori.no", "camper")
    //   .then((userCredential) => {
    //     // Signed in

    //     const user = userCredential;
    //     console.log(user);
    //     console.log(user.user.uid);
    //     console.log(user.user.email);

    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     console.log(errorMessage);
    //   });
  };

  return loggedIn ? (
    <Navigate
      to={!token ? "/MyBusiness" : `/MyBusiness?token=${token}`}
      replace
    />
  ) : (
    <>
      <Header />
      {page === "newAccount" ? (
        <div className='loginPageMainContainer'>
          <div className='loginpageLeft'>
            <h1>Bli en del av Mapli</h1>
            <h2>
              Bli en del av vår kartløsning slik at reisende enkelt kan finne
              fram til din bedrift
            </h2>
          </div>
          <div className='loginpageRight'>
            <form className='loginForm'>
              <h2>Opprett bruker</h2>
              <div className='loginFormInputFields'>
                <span>E-post</span>
                <input
                  placeholder='E-post'
                  value={emailInput}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                  }}
                  type='email'
                />
                <span>Passord</span>
                <input
                  placeholder='Passord'
                  value={passwordInput}
                  onChange={(e) => {
                    setPasswordInput(e.target.value);
                  }}
                  type='password'
                />
              </div>

              <a
                href={!token ? "/login" : `/login?token=${token}`}
                style={{ display: "flex", color: "black", marginTop: "10px" }}>
                <div style={{ textDecoration: "underline" }}>
                  Har du allerede en bruker?
                </div>
                <div className='loginIfUser'>Logg inn</div>
              </a>

              <button className='loginButton' onClick={onNewAccount}>
                Opprett bruker
              </button>
              <div className='termsTextBoks'>
                Ved å fortsette aksepterer jeg vilkårene for
                <a href='#' className='loginIfUser'>
                  Mapli-konto
                </a>
              </div>
            </form>
          </div>
        </div>
      ) : page === "forgotPassword" ? (
        <div className='loginPageMainContainer'>
          <div className='loginpageLeft'>
            <h1>Bli en del av Mapli</h1>
            <h2>
              Bli en del av vår kartløsning slik at reisende enkelt kan finne
              fram til din bedrift
            </h2>
          </div>
          <div className='loginpageRight'>
            <form className='loginForm'>
              <h2>Glemt passordet?</h2>
              <div className='loginFormInputFields'>
                <span>E-post</span>
                <input
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                  }}
                  placeholder='E-post'
                  type='email'
                />
              </div>

              <button
                className='loginButton'
                style={{ marginTop: "20px" }}
                onClick={onForgotPassword}>
                Reset passord
              </button>

              <a
                href={!token ? "/login" : `/login?token=${token}`}
                style={{
                  display: "flex",
                  color: "black",
                  marginTop: "10px",
                  marginBottom: "35px",
                }}>
                <div style={{ textDecoration: "underline" }}>Tilbake til</div>
                <div className='loginIfUser'>Logg inn</div>
              </a>
            </form>
          </div>
        </div>
      ) : (
        <div className='loginPageMainContainer'>
          <div className='loginpageLeft'>
            <h1>Bli en del av Mapli</h1>
            <h2>
              Bli en del av vår kartløsning slik at reisende enkelt kan finne
              fram til din bedrift
            </h2>
          </div>
          <div className='loginpageRight'>
            <form className='loginForm'>
              <h2>Logg inn</h2>
              <div className='loginFormInputFields'>
                <span>E-post</span>
                <input
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                  }}
                  placeholder='E-post'
                  type='email'
                />
                <span>Passord</span>
                <input
                  onChange={(e) => {
                    setPasswordInput(e.target.value);
                  }}
                  placeholder='Passord'
                  type='password'
                />
              </div>
              <a
                href={
                  !token
                    ? "/login/forgotPassword"
                    : `/login/forgotPassword?token=${token}`
                }
                className='forgotPassword'>
                Glemt passordet?
              </a>

              <button className='loginButton' onClick={onLogin}>
                Logg inn
              </button>
              <a
                href={
                  !token
                    ? "/login/newAccount"
                    : `/login/newAccount?token=${token}`
                }
                className='newAccountButton'>
                Opprett bruker
              </a>
            </form>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default LoginPage;
