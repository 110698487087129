import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore, GeoFirestore } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import EditEvent from "./editEvent";

const db = firestore;

const Events = ({ currentBusinessID }) => {
  const [newEventName, setNewEventName] = useState("");
  const [newEvent, setNewEvent] = useState(false);
  const [price, setPrice] = useState(0);
  const [missingFields, setMissingFields] = useState({
    name: false,
    location: false,
    startDate: false,
    endDate: false,
  });
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [eventLocation, setEventLocation] = useState(null);
  const [changingLocation, setchangingLocation] = useState(false);
  const [businessInformation, setBusinessInformation] = useState(null);
  const [freeDays, setFreedays] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [businessLocations, setBusinessLocations] = useState([]);
  const navigate = useNavigate();
  let { edit } = useParams();

  useEffect(() => {
    const effectFunction = async () => {
      const userInfo = doc(db, "businesses", currentBusinessID);
      const userInformation = await getDoc(userInfo);
      const userData = userInformation.data();
      const menus = userData.menus;

      setBusinessInformation(userData);
      setFreedays(userData.freeEventDays ?? 0);

      let activeLocations = [];

      for (const menu of menus) {
        // Assuming each menu has an identifier to get the specific locations collection
        const locationsRef = doc(db, "lokasjoner", menu);
        const querySnapshot = await getDoc(locationsRef);

        if (querySnapshot.data().active === true) {
          activeLocations.push(querySnapshot);
        }
      }
      setBusinessLocations(activeLocations);

      if (userData.events !== undefined && userData.events.length > 0) {
        fetchEvents(userData.events);
      }
    };

    const isValidID =
      currentBusinessID &&
      typeof currentBusinessID === "string" &&
      currentBusinessID.length > 0;

    if (isValidID) {
      effectFunction();
    } else {
      console.log("Invalid business ID. Please check the ID or sign in.");
    }
  }, [currentBusinessID]);

  const fetchEvents = async (listData) => {
    const fetchedData = await Promise.all(
      listData.map(async (value) => {
        const docRef = doc(db, "events", value);
        const docSnapshot = await getDoc(docRef);
        return docSnapshot;
      })
    );

    setEventList(fetchedData);
  };

  const onAddNewEvent = async () => {
    const userInfo = doc(db, "businesses", currentBusinessID);
    const geocollection = GeoFirestore.collection("events");
    const userInformation = await getDoc(userInfo);
    const userData = userInformation.data();

    const fields = {
      name: newEventName === "",
      location: eventLocation === null,
      startDate: startDate === 0,
      endDate: endDate === 0 || endDate < startDate,
    };

    setMissingFields(fields);

    // Optional: Do something if there are missing fields, like prevent form submission
    if (fields.name || fields.location || fields.startDate || fields.endDate) {
      console.log("Please fill all the fields.");
      return;
    }

    let businessEvents = userData.events ? userData.events : [];

    const newEvent = await geocollection.add({
      locationName: newEventName,
      aboutBusiness: "",
      aboutEvent: "",
      active: false,
      startDate: startDate,
      endDate: endDate,
      eventOrganiser: eventLocation.id,
      lat: eventLocation.data().lat,
      lng: eventLocation.data().lng,
      paymentPrice: price,
      averagePrice: 0,
      tags: [],
      tagsLang: { en_US: [], nb_NO: [] },
      type: "event",
      coordinates: new firebase.firestore.GeoPoint(
        eventLocation.data().lat,
        eventLocation.data().lng
      ),
    });

    businessEvents.push(newEvent.id);

    await updateDoc(userInfo, {
      events: businessEvents,
    });

    navigate("/MyBusiness/events/" + newEvent.id);
  };

  const onGoBack = () => {
    setNewEvent(false);
    setPrice(0);
    setStartDate(0);
    setEndDate(0);
    setNewEventName("");
    setEventLocation(null);
    setMissingFields({
      name: false,
      location: false,
      startDate: false,
      endDate: false,
    });
  };

  const onSetLocation = (event) => {
    setEventLocation(event);
    setchangingLocation(false);
  };

  useEffect(() => {
    if (startDate !== 0 && endDate !== 0) {
      if (endDate > startDate) {
        let newPrice = 0;
        newPrice = endDate - startDate;
        let newPriceInDays = newPrice / (3600 * 24);
        if (freeDays > 0) {
          if (newPriceInDays - freeDays > 0) {
            newPriceInDays = newPriceInDays - freeDays;
          } else {
            newPriceInDays = 0;
          }
        }

        setPrice(newPriceInDays * 25);
        console.log(newPriceInDays);
      } else {
        setPrice(0);
      }
    }
  }, [startDate, endDate]);

  return edit !== undefined ? (
    <EditEvent currentBusinessID={currentBusinessID} />
  ) : changingLocation ? (
    <>
      <div>
        <h1>Hvilken lokasjon gjelder arragementet for?</h1>
        {businessLocations.length > 0 ? (
          businessLocations.map((lokasjonsInformasjon, rowIndex) => {
            return (
              <div
                key={rowIndex}
                className='box'
                style={{ marginBottom: "15px" }}>
                <div className='businessOverView'>
                  <label className='locationNameLabel'>
                    {lokasjonsInformasjon.data()?.locationName}
                  </label>
                  <label>
                    {lokasjonsInformasjon.data()?.adress
                      ? lokasjonsInformasjon.data()?.adress
                      : "-"}
                  </label>
                  <div>
                    <a
                      onClick={() => {
                        onSetLocation(lokasjonsInformasjon);
                      }}
                      className='universalButtonRed'
                      style={{ marginLeft: "5px" }}>
                      Velg
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p style={{ textAlign: "center", marginTop: 0 }}>
            Ingen lokasjoner å vise til
          </p>
        )}
      </div>
    </>
  ) : newEvent ? (
    <>
      <div>
        <h1>Opprett ny lokasjon</h1>
        <div
          className='box'
          style={{
            borderTopLeftRadius: 0,
            padding: 25,
            paddingRight: 35,
            marginBottom: "50px",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "38%",
              }}>
              <label className='editPageLabel'>Navn på arrangementet</label>
              <input
                placeholder='Navn'
                value={newEventName}
                onChange={(x) => {
                  setNewEventName(x.target.value);
                }}
              />
              <label
                style={{
                  display: missingFields.name ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe!
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "58%",
              }}>
              <label className='editPageLabel'>
                Hvilken lokasjon gjelder arragementet for?
              </label>
              <input
                placeholder='Lokasjon'
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  setchangingLocation(true);
                }}
                value={
                  eventLocation !== null
                    ? eventLocation.data().adress !== undefined
                      ? eventLocation.data().locationName +
                        " - " +
                        eventLocation.data().adress
                      : eventLocation.data().locationName
                    : ""
                }
              />
              <label
                style={{
                  display: missingFields.location ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe!
              </label>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
              }}>
              <label className='editPageLabel'>Annonse start</label>
              <input
                placeholder='-- -- --'
                type='date'
                style={{ paddingRight: "10px" }}
                onChange={(x) => {
                  setStartDate(new Date(x.target.value).getTime() / 1000);
                }}
              />
              <label
                style={{
                  display: missingFields.startDate ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe!
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
              }}>
              <label className='editPageLabel'>Annonse slutt</label>
              <input
                placeholder='-- -- --'
                type='date'
                style={{ paddingRight: "10px" }}
                onChange={(x) => {
                  setEndDate(new Date(x.target.value).getTime() / 1000);
                }}
              />
              <label
                style={{
                  display: missingFields.endDate ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe! Datoen må være etter startdato.
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
              }}>
              <label className='editPageLabel'>Pris</label>
              <input disabled value={price + " NOK"} />
            </div>
          </div>
          {freeDays !== 0 ? (
            <div style={{ marginTop: "15px" }}>
              Du har {freeDays} gratis annonsedager igjen å bruke. Disse vil
              automatisk trekkes fra prisen.
            </div>
          ) : (
            <></>
          )}
          <div>
            <button
              onClick={onAddNewEvent}
              className='universalButtonRed'
              style={{
                width: "150px",
                marginTop: "25px",
                marginRight: "10px",
              }}>
              Gå videre
            </button>
            <button
              style={{
                width: "150px",
                marginTop: "25px",
              }}
              onClick={onGoBack}
              className='universalButton'>
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div>
        <h1>Arrangementer</h1>
        <button
          className='universalButton'
          onClick={() => {
            setNewEvent(true);
          }}>
          + Opprett arrangement
        </button>
        <div>
          <div className='eventHeader'>Framtidige arrangementer</div>
          {eventList.map((x, index) => {
            if (x.data().endDate * 1000 > new Date().getTime()) {
              return (
                <div className='eventMain'>
                  <label style={{ fontWeight: "bold" }}>
                    {x.data().locationName}
                  </label>
                  <label>
                    Dato:{" "}
                    {new Date(x.data().endDate * 1000).toLocaleDateString(
                      "nb-NO",
                      {
                        day: "2-digit", // Ensure the day is two digits
                        month: "2-digit", // Ensure the month is two digits
                        year: "numeric", // Full year
                      }
                    )}
                  </label>
                  <div>
                    <label
                      style={{
                        color: x.data().active ? "#52c78c" : "#f9615b",
                      }}>
                      {x.data().active ? "Aktiv" : "Ikke aktiv"}
                    </label>
                    <a
                      href={"/MyBusiness/events/" + x.id}
                      className='universalButtonRed'>
                      Rediger
                    </a>
                  </div>
                </div>
              );
            }
          })}
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className='eventHeader'>Eldre arrangementer</div>
          {eventList.map((x, index) => {
            if (x.data().endDate * 1000 < new Date().getTime()) {
              return (
                <div className='eventMain'>
                  <label style={{ fontWeight: "bold" }}>
                    {x.data().locationName}
                  </label>
                  <label>
                    Dato:{" "}
                    {new Date(x.data().endDate * 1000).toLocaleDateString(
                      "nb-NO",
                      {
                        day: "2-digit", // Ensure the day is two digits
                        month: "2-digit", // Ensure the month is two digits
                        year: "numeric", // Full year
                      }
                    )}
                  </label>
                  <div>
                    <label
                      style={{
                        color: x.data().active ? "#52c78c" : "#f9615b",
                      }}>
                      {x.data().active ? "Aktiv" : "Ikke aktiv"}
                    </label>
                    <a
                      href={"/MyBusiness/events/" + x.id}
                      className='universalButton'>
                      Åpne
                    </a>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default Events;
