import React from "react";
import "../css/homepage.css";
import {
  RiFacebookCircleFill,
  RiInstagramFill,
  RiLinkedinBoxFill,
} from "react-icons/ri";
import MapliBadgeWhite from "../assets/images/Hvit_Symbol.svg";

const Footer = (props) => {
  return (
    <footer className='footerContainer'>
      <img src={MapliBadgeWhite} height='70px' />
      <div className='footerContact'>
        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>Hjelp</p>

        <a href='/faq'>
          <p>FAQ / Kontakt</p>
        </a>
        {/* <a href='/faq'>
          <p>Vilkår og betingelser</p>
        </a> */}
      </div>
      <div className='footerContact'>
        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
          Last ned appen
        </p>
        <a href='#'>
          <p>Android App</p>
        </a>
        <a href='#'>
          <p>iPhone App</p>
        </a>
      </div>
      <div className='footerContact'>
        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>Følg oss</p>
        <div className='footerSocial'>
          <RiFacebookCircleFill size={35} style={{ paddingRight: 10 }} />
          <RiInstagramFill size={35} style={{ paddingRight: 10 }} />
          <RiLinkedinBoxFill size={35} style={{ paddingRight: 10 }} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
