import React, { useState } from "react";
import { Map, Marker } from "react-map-gl";
import { useSearchParams } from "react-router-dom";
import MapMarkerPin from "../components/mapMarkerPin";

const MapWidget = (props) => {
  const [searchParams, setSearchParams] = useSearchParams("");
  const latitude = searchParams.get("lat");
  const longitude = searchParams.get("lng");

  const mapToken =
    "pk.eyJ1Ijoic3RlZmZlbmxpZTEiLCJhIjoiY2xqNDBzNnpyMDlxeTNlczFidW9seGxsOSJ9.DNY1zitSfG0YZ3SvjARdHg";

  const [initialViewport, setInitialViewport] = useState({
    longitude: longitude === null ? 14.404916 : longitude,
    latitude: latitude === null ? 67.280357 : latitude,
    zoom: 13,
  });

  const [viewport, setViewport] = useState({
    longitude: longitude === null ? 14.404916 : longitude,
    latitude: latitude === null ? 67.280357 : latitude,
    zoom: 13,
  });

  const onChangeViewport = (x) => {
    setViewport(x.viewState);
    console.log(viewport);
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Map
        {...viewport}
        onMove={(viewport) => onChangeViewport(viewport)}
        width='100%'
        height='100%'
        mapStyle='mapbox://styles/mapbox/streets-v9'
        mapboxAccessToken={mapToken}>
        {longitude !== null ? (
          <Marker
            longitude={longitude}
            latitude={latitude}
            offset={[12 * 13, (-24 / 2) * 13]}>
            <MapMarkerPin size={20} />
          </Marker>
        ) : (
          <Marker
            longitude={14.404916}
            latitude={67.280357}
            offset={[viewport.zoom * 24, viewport.zoom * -24]}>
            <MapMarkerPin size={20} />
          </Marker>
        )}
      </Map>
    </div>
  );
};

export default MapWidget;
