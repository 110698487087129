import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import {
  doc,
  getDoc,
  updateDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import axios from "axios";
import Modal from "react-modal";
import { firestore } from "../../firebase";

const auth = getAuth();
const db = firestore;

const Profile = ({ currentBusinessID }) => {
  const [invitedEmail, setInvitedEmail] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [editProfileInfo, setEditProfileInfo] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  useEffect(() => {
    const effectFunction = async () => {
      const userInfo = doc(db, "businesses", currentBusinessID);
      const userInformation = await getDoc(userInfo);
      setUserProfile(userInformation.data());
    };

    const isValidID =
      currentBusinessID &&
      typeof currentBusinessID === "string" &&
      currentBusinessID.length > 0;

    if (isValidID) {
      effectFunction();
    } else {
      console.log("Invalid business ID. Please check the ID or sign in.");
    }
  }, [currentBusinessID]);

  const sendJoinBusinessInvite = async (e) => {
    const userInfo = doc(db, "businesses", currentBusinessID);

    let allInvitedEmails = userProfile.invitedEmails ?? [];

    e.preventDefault();
    if (invitedEmail.length < 1) {
      console.log("too little!");
    } else {
      console.log(
        "email: " + invitedEmail,
        "businessID: " + currentBusinessID,
        "businessName: " + userProfile.name
      );
      allInvitedEmails.push(invitedEmail);
      updateDoc(userInfo, {
        invitedEmails: allInvitedEmails,
      });
      setInviteSent(true);
      setInvitedEmail("");
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/sendInvite/${invitedEmail}/${currentBusinessID}/${userProfile.name}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        });
    }
  };

  return (
    <>
      <div className='editLocationPage'>
        <h1>Profil</h1>
        <div className='profileInfoBoxes'>
          {editProfileInfo ? (
            <>
              <div className='box'>
                <div className='innerBox'>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: "bold",
                    }}>
                    <label>Kundenr.:</label>
                    <label>E-post:</label>
                    <label>Adresse:</label>
                    <label>Telefon:</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "gray",
                    }}>
                    <label>{userProfile?.customerNumber}</label>
                    <input
                      placeholder='e-post'
                      value={userProfile?.businessContactEmail}
                      onChange={(x) => {}}
                    />
                    <input placeholder='adresse' value={userProfile?.adress} />
                    <input
                      placeholder='telefon'
                      value={userProfile?.phoneNumber}
                    />
                  </div>
                </div>
                <button
                  className='universalButtonRed'
                  style={{ marginRight: "5px" }}
                  onClick={() => {}}>
                  Lagre endringer
                </button>
                <button className='universalButton' onClick={() => {}}>
                  Avbryt
                </button>
              </div>
            </>
          ) : (
            <>
              <div className='box'>
                <div className='innerBox'>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: "bold",
                    }}>
                    <label>Kundenr.:</label>
                    <label>E-post:</label>
                    <label>Adresse:</label>
                    <label>Telefon:</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "gray",
                    }}>
                    <label>{userProfile?.customerNumber}</label>
                    <label>{userProfile?.businessContactEmail}</label>
                    <label>{userProfile?.adress}</label>
                    <label>{userProfile?.phoneNumber}</label>
                  </div>
                </div>
                <button
                  className='universalButton'
                  onClick={() => {
                    setEditProfileInfo(true);
                  }}>
                  Rediger opplysninger
                </button>
              </div>
            </>
          )}
        </div>
        <div className='profileUserAccess'>
          <div className='box'>
            <label style={{ fontWeight: "bold" }}>Brukeradgang</label>
            {userProfile?.userAccessEmails?.map((val, index) => (
              <div className='historyTableRow' key={index}>
                <div style={{ marginLeft: "5px" }}>{val}</div>
              </div>
            ))}
            <div style={{ marginTop: "10px" }}>
              <a
                href='#'
                onClick={() => {
                  setOpenInviteModal(true);
                }}>
                + Legg til ny
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openInviteModal}
        onRequestClose={() => {
          setOpenInviteModal(false);
        }}
        className='modalStyle'
        style={{
          content: {
            width: "17%",
          },
        }}>
        <div className='editActiveModal'>
          <label className='editPageLabel'>
            Inviter noen til å endre bedriftsprofilen
          </label>
          <form onSubmit={sendJoinBusinessInvite}>
            <input
              type='email'
              value={invitedEmail}
              placeholder='Epostadresse'
              onChange={(e) => setInvitedEmail(e.target.value)}
              required
            />
            <label
              style={{
                display: inviteSent ? "block" : "none",
                color: "#52c78c",
              }}>
              Invitasjon sendt!
            </label>
            <button
              onClick={sendJoinBusinessInvite}
              className='universalButtonRed'
              style={{
                width: "100%",
                marginTop: 20,
              }}>
              Send invitasjon
            </button>
          </form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <button
              onClick={() => {
                setOpenInviteModal(false);
              }}
              className='universalButton'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Tilbake
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
