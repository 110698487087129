import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";

const EditReviews = ({
  googleBusinessDetails,
  setGoogleBusinessDetails,
  tripadvisorLink,
  setTripadvisorLink,
  yelpLink,
  setYelpLink,
}) => {
  const getGoogleBusinessDetails = (id) => {
    fetch(
      `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/googleBusinessDetails/${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        // businessDetailsData
        // reviewData
        // Update last tripadvisorUpdateDate
        // Update businessDetailsData.rating
        // Save new collection for reviews with the last 5
        // + todays date
        setGoogleBusinessDetails(data.businessDetailsData.result);

        // THIS DOES INDEED WORK!!!
        // Henter Gatsby sin lokasjon og pakker den inn i google ratings
        // const docRef = doc(db, "lokasjoner", "SxbYDq46eAyKQSk1xaBY");

        // const subcollectionRef = collection(docRef, "googleRating");
        // const dayDocumentRef = doc(subcollectionRef, "googleRating");

        // setDoc(dayDocumentRef, {
        //   reviews: data.businessDetailsData.result.reviews,
        //   date: new Date(),
        // });

        // googleNumReviews = googleBusinessDetails.user_ratings_total;
        // googleRating = googleBusinessDetails.rating
        // googleId = googleBusinessDetails.place_id
        // googleLink = googleBusinessDetails.url
      });
  };

  return (
    <div>
      <label className='editPageLabel'>
        Øk synligheten til bedriften ved å koble til Tripadvisor/Yelp/Google
        Reviews!
      </label>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
        }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <label className='editPageLabel'>Tripadvisor</label>
          <label
            style={{
              display: tripadvisorLink?.length > 0 ? "block" : "none",
              color: "#52c78c",
              marginLeft: "5px",
              fontSize: 15,
            }}>
            i bruk
          </label>
        </div>
        <input
          placeholder='Link til lokasjonens Tripadvisor-side'
          value={tripadvisorLink}
          onChange={(x) => {
            setTripadvisorLink(x.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
        }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <label className='editPageLabel'>Yelp</label>
          <label
            style={{
              display: yelpLink?.length > 0 ? "block" : "none",
              color: "#52c78c",
              marginLeft: "5px",
              fontSize: 15,
            }}>
            i bruk
          </label>
        </div>
        <input
          placeholder='Link til lokasjonens Yelp-side'
          value={yelpLink}
          onChange={(x) => {
            setYelpLink(x.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
        }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <label className='editPageLabel'>Google Reviews</label>
          <label
            style={{
              display: googleBusinessDetails ? "block" : "none",
              color: "#52c78c",
              marginLeft: "5px",
              fontSize: 15,
            }}>
            i bruk
          </label>
        </div>
        {/* <input placeholder='Link til lokasjonens Google Reviews-side' /> */}
        <Autocomplete
          options={{ types: ["places"] + ["establishment"] + ["address"] }}
          placeholder='Søk opp bedriften med Google'
          apiKey={"AIzaSyBzqNdOb1GQUpeP2bFfsed64Yp_hEALAQ4"}
          onPlaceSelected={(place) => {
            getGoogleBusinessDetails(place.place_id);
            // console.log(place.place_id);
          }}
        />
      </div>
    </div>
  );
};

export default EditReviews;
