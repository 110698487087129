import React, { useState, useEffect } from "react";
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc, setDoc, collection } from "firebase/firestore";
import { firestore, GeoFirestore } from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Autocomplete from "react-google-autocomplete";
import EditMyLocation from "./editMyLocation";

const auth = getAuth();
const db = firestore;

const mapToken =
  "pk.eyJ1Ijoic3RlZmZlbmxpZTEiLCJhIjoiY2xqNDBzNnpyMDlxeTNlczFidW9seGxsOSJ9.DNY1zitSfG0YZ3SvjARdHg";

const MyLocations = ({ currentBusinessID }) => {
  const [lokasjoner, setLokasjoner] = useState(false);
  const [business, setBusiness] = useState(null);
  const [newLocation, setNewLocation] = useState(false);
  const [userMenus, setUserLocations] = useState([]);
  const [user, setUser] = useState(null);
  const [locationsInfo, setLocationsInfo] = useState([]);
  const [locationType, setLocationType] = useState("");
  const [newLocationName, setNewLocationName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [missingFields, setMissingFields] = useState({
    type: false,
    name: false,
    lat: false,
    long: false,
    search: false,
  });
  const [googleBusinessDetails, setGoogleBusinessDetails] = useState(null);

  let { edit } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setUser(auth.currentUser);
      } else {
      }
    });
  }, [auth]);

  useEffect(() => {
    console.log(currentBusinessID);

    const effectFunction = async () => {
      const userInfo = doc(db, "businesses", currentBusinessID);
      const userLocationList = await getDoc(userInfo);

      setUserLocations(userLocationList.data().menus);
      setBusiness(userLocationList.data());

      if (
        userLocationList.data().menus !== undefined &&
        userLocationList.data().menus.length > 0
      ) {
        fetchData(userLocationList.data().menus);
      }
    };

    // Example of a basic format check, e.g., checking if the ID is a non-empty string
    const isValidID =
      currentBusinessID &&
      typeof currentBusinessID === "string" &&
      currentBusinessID.length > 0;

    if (isValidID) {
      effectFunction();
    } else {
      console.log("Invalid business ID. Please check the ID or sign in.");
    }
  }, [currentBusinessID]);

  const fetchData = async (listData) => {
    const fetchedData = await Promise.all(
      listData.map(async (value) => {
        const docRef = doc(db, "lokasjoner", value);
        const docSnapshot = await getDoc(docRef);
        return docSnapshot;
      })
    );

    setLocationsInfo(fetchedData);
    setLokasjoner(true);
  };

  const getGoogleBusinessDetails = (id) => {
    fetch(
      `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/googleBusinessDetails/${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        setGoogleBusinessDetails(data.businessDetailsData.result);
      });
  };

  const onAddNewLocation = async () => {
    const fields = {
      type: locationType === "",
      name: newLocationName === "",
      lat: latitude === "",
      long: longitude === "",
    };

    setMissingFields(fields);

    // Optional: Do something if there are missing fields, like prevent form submission
    if (fields.type || fields.name || fields.lat || fields.long) {
      console.log("Please fill all the fields.");
      return;
    }

    const userInfo = doc(db, "businesses", currentBusinessID);
    const geocollection = GeoFirestore.collection("lokasjoner");
    const zoom = 12;
    const imageSize = "300x400";
    const coordinates = `${longitude},${latitude}`;
    const imageUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${coordinates},${zoom},0,0/${imageSize}?access_token=${mapToken}`;

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const base64 = await blobToBase64(blob);
      const dataUrl = `data:image/png;base64,${base64}`;

      const newLocationRef = await geocollection.add({
        locationName: newLocationName,
        active: false,
        lat: latitude,
        lng: longitude,
        menus: [],
        mapImage: dataUrl,
        type: locationType,
        googleNumReviews: googleBusinessDetails.user_ratings_total,
        googleRating: googleBusinessDetails.rating,
        googleId: googleBusinessDetails.place_id,
        googleLink: googleBusinessDetails.url,
        owner: currentBusinessID,
        // The coordinates field must be a GeoPoint!
        coordinates: new firebase.firestore.GeoPoint(latitude, longitude),
      });
      const docRef = doc(db, "lokasjoner", newLocationRef.id);

      // Reference the subcollection
      const subcollectionRef = collection(docRef, "googleRating");
      const dayDocumentRef = doc(subcollectionRef, "googleRating");

      await setDoc(dayDocumentRef, {
        reviews: googleBusinessDetails.reviews,
        date: new Date(),
      });

      const newUserLocations = userMenus;

      newUserLocations.push(newLocationRef.id);

      await updateDoc(userInfo, {
        menus: newUserLocations,
      });

      console.log("Data added successfully!");
      navigate("/MyBusiness/myLocations/" + newLocationRef.id);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const onGoBack = () => {
    setLocationType("");
    setNewLocationName("");
    setLatitude("");
    setLongitude("");
    setMissingFields({
      type: false,
      name: false,
      lat: false,
      long: false,
      search: false,
    });
    setNewLocation(false);
  };

  return edit !== undefined ? (
    <EditMyLocation currentBusinessID={currentBusinessID} />
  ) : newLocation ? (
    <>
      <div>
        <h1>Opprett ny lokasjon</h1>
        <div
          className='box'
          style={{
            borderTopLeftRadius: 0,
            padding: 25,
            paddingRight: 35,
            marginBottom: "50px",
          }}>
          <label className='editPageLabel'>Type lokasjon</label>
          <div style={{ display: "flex", marginTop: "12px" }}>
            <div
              style={{
                display: "flex",
              }}>
              <input
                type='checkbox'
                className='inputCheckbox'
                checked={locationType === "restaurant"}
                onChange={(x) => {
                  locationType === "restaurant"
                    ? setLocationType("")
                    : setLocationType("restaurant");
                }}
              />
              <label className='editPageLabel' style={{ color: "#5d3160" }}>
                Restaurant
              </label>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "20px",
              }}>
              <input
                type='checkbox'
                className='inputCheckbox'
                checked={locationType === "activity"}
                onChange={(x) => {
                  locationType === "activity"
                    ? setLocationType("")
                    : setLocationType("activity");
                }}
              />
              <label className='editPageLabel' style={{ color: "#52c78c" }}>
                Aktivitet
              </label>
            </div>
          </div>
          <label
            style={{
              display: missingFields.type ? "block" : "none",
              color: "#f9615b",
            }}>
            Her mangler det noe!
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "38%",
              }}>
              <label className='editPageLabel'>Navn</label>
              <input
                placeholder='Navn'
                value={newLocationName}
                onChange={(x) => {
                  setNewLocationName(x.target.value);
                }}
              />
              <label
                style={{
                  display: missingFields.name ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe!
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "58%",
              }}>
              <label className='editPageLabel'>
                Er bedriften på google? Søk etter bedriftens lokasjon
              </label>
              <Autocomplete
                options={{
                  types: ["places"] + ["establishment"] + ["address"],
                }}
                placeholder='Søk etter bedriftens lokasjon eller adresse'
                apiKey={"AIzaSyBzqNdOb1GQUpeP2bFfsed64Yp_hEALAQ4"}
                onPlaceSelected={(place) => {
                  getGoogleBusinessDetails(place.place_id);
                  setLatitude(place.geometry.location.lat());
                  setLongitude(place.geometry.location.lng());
                }}
              />
              <label
                style={{
                  display: missingFields.search ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe!
              </label>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <label className='editPageLabel'>Koordinater</label>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}>
                <input
                  placeholder='Lengdegrad'
                  onChange={(x) => setLongitude(x.target.value)}
                  value={longitude}
                  disabled
                />
                <label
                  style={{
                    display: missingFields.long ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe! Gjør et søk på en lokasjon eller en
                  adresse!
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}>
                <input
                  placeholder='Breddegrad'
                  onChange={(x) => setLatitude(x.target.value)}
                  value={latitude}
                  disabled
                />
                <label
                  style={{
                    display: missingFields.lat ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe! Gjør et søk på en lokasjon eller en
                  adresse!
                </label>
              </div>
            </div>
          </div>

          <div>
            <button
              onClick={onAddNewLocation}
              className='universalButtonRed'
              style={{
                width: "150px",
                marginTop: "25px",
                marginRight: "10px",
              }}>
              Opprett
            </button>
            <button
              style={{
                width: "150px",
                marginTop: "25px",
              }}
              onClick={onGoBack}
              className='universalButton'>
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div>
      <h1>Mine lokasjoner</h1>
      <button
        className='universalButton'
        style={{
          marginBottom:
            business !== null && !business.activeSub ? "20px" : "35px",
        }}
        onClick={() => {
          setNewLocation(true);
        }}>
        + Ny lokasjon
      </button>
      {business !== null && !business.activeSub ? (
        <div className='aboutLocationEditting' style={{ marginBottom: "25px" }}>
          <label>
            Du har ikke et aktivt abonnement. Aktive lokasjoner vises ikke til
            publikum.
          </label>
          <label>
            Start et nytt{" "}
            <a
              style={{
                color: "#f9615b",
              }}
              href='/MyBusiness/subscription'>
              abonnement
            </a>{" "}
            for å sette bedriften på kartet!
          </label>
        </div>
      ) : (
        <></>
      )}
      {lokasjoner ? (
        locationsInfo.map((lokasjonsInformasjon, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className='box'
              style={{ marginBottom: "15px" }}>
              <div className='businessOverView'>
                <label className='locationNameLabel'>
                  {lokasjonsInformasjon.data()?.locationName}
                </label>
                <label>
                  {lokasjonsInformasjon.data()?.adress
                    ? lokasjonsInformasjon.data()?.adress
                    : "-"}
                </label>
                <div>
                  <label
                    style={{
                      color: lokasjonsInformasjon.data()?.active
                        ? "#52c78c"
                        : "#f9615b",
                    }}>
                    {lokasjonsInformasjon.data()?.active
                      ? "Aktiv"
                      : "Ikke Aktiv"}
                  </label>
                  <a
                    href={"/MyBusiness/myLocations/" + lokasjonsInformasjon.id}
                    className='universalButtonRed'
                    style={{ marginLeft: "5px" }}>
                    Rediger
                  </a>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p style={{ textAlign: "center", marginTop: 0 }}>
          Ingen lokasjoner å vise til
        </p>
      )}
    </div>
  );
};

export default MyLocations;
