import React, { useEffect } from "react";
import "./css/homepage.css";
import AppleBadge from "./assets/images/apple-appstore-badge.svg";
import GooglePlayBadge from "./assets/images/googleplay.png";
import Footer from "./components/footer";
import HeaderHomepage from "./components/headerHomepage";
import Header from "./components/header";

const HomePage = (props) => {
  useEffect(() => {
    document.title = "Mapli";
  }, []);

  return (
    <div className='homePageContainer'>
      <HeaderHomepage />

      <div className='homePageMainContainer'>
        <div className='containerInfo'>
          <p className='containerInfoText'>
            Reisevennen som hjelper deg finne frem til digge{" "}
            <div className='textHighlight' id='restaurants'>
              restauranter
            </div>
            , morsomme{" "}
            <div className='textHighlight' id='activities'>
              aktiviteter
            </div>{" "}
            og spennende{" "}
            <div className='textHighlight' id='events'>
              arrangementer
            </div>{" "}
            i nærheten!
          </p>
          <div className='textHighlight' id='downloadApp'>
            Last ned appen!
          </div>
          <div className='appstoreButtons'>
            <div style={{ cursor: "pointer" }}>
              <img src={AppleBadge} height='40px' style={{ marginRight: 5 }} />
            </div>
            <div style={{ cursor: "pointer" }}>
              <img
                src={GooglePlayBadge}
                style={{ marginLeft: 5 }}
                height='39px'
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
