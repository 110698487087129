import React, { useState, useEffect } from "react";
import ExcelFileUpload from "./excelFileUpload";
import * as XLSX from "xlsx";

const EditMenu = ({
  newMenu,
  setNewMenu,
  lokasjonstype,
  menuList,
  setMenuList,
  editLanguage,
  newMenuEnglish,
  setNewMenuEnglish,
  menuListEnglish,
  setMenuListEnglish,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [visibleMenus, setVisibleMenus] = useState({});
  const [newMenuFields, setNewMenuFields] = useState({
    name: "",
    allergies: "",
    price: "",
    description: "",
    newCategory: "",
  });
  const [missingFields, setMissingFields] = useState({
    name: false,
    allergies: false,
    price: false,
    description: false,
    category: false,
    newCategory: false,
  });
  const [excelJsonData, setExelJsonData] = useState(null);

  // Set initial visibility
  useEffect(() => {
    const menuVisibility = {};
    Object.keys(menuList).forEach((element) => {
      menuVisibility[element] = false; // Start with all menus expanded
    });

    setVisibleMenus(menuVisibility);
  }, [menuList]);

  const toggleMenuVisibility = (element) => {
    setVisibleMenus((prevVisibleMenus) => ({
      ...prevVisibleMenus,
      [element]: !prevVisibleMenus[element],
    }));
  };

  const addNewMenuItem = () => {
    // Clone the menuList to avoid mutating the original object directly.
    let updatedMenuItems = [...menuList];

    let isAnyFieldMissing = false;

    let updatedMissingFields = {
      name: false,
      allergies: false,
      price: false,
      description: false,
      category: false,
      newCategory: selectedCategory === "newCategory" ? false : true,
    };

    // Check each field except 'newCategory'
    Object.keys(newMenuFields).forEach((key) => {
      if (key !== "newCategory" && newMenuFields[key].trim() === "") {
        updatedMissingFields[key] = true;
        isAnyFieldMissing = true;
      }
    });

    // Check 'newCategory' only if selectedCategory is 'newCategory'
    if (selectedCategory === "") {
      updatedMissingFields.category = true;
      isAnyFieldMissing = true;
    }
    if (
      selectedCategory === "newCategory" &&
      newMenuFields.newCategory.trim() === ""
    ) {
      updatedMissingFields.newCategory = true;
      isAnyFieldMissing = true;
    }

    // Update the missingFields state
    setMissingFields(updatedMissingFields);

    // If any field is missing, return early
    if (isAnyFieldMissing) {
      return;
    } else {
      if (selectedCategory === "newCategory") {
        // Find the category or create a new one
        let categoryIndex = updatedMenuItems.findIndex(
          (item) => item.category === newMenuFields.newCategory
        );

        if (categoryIndex === -1) {
          updatedMenuItems.push({
            category: newMenuFields.newCategory,
            menuItems: [],
            allergies: [],
            prices: [],
            descriptions: [],
          });
          categoryIndex = updatedMenuItems.length - 1;
        }

        // Now that you've ensured the category and its arrays exist, you can safely push the new values
        updatedMenuItems[categoryIndex].menuItems.push(newMenuFields.name);
        updatedMenuItems[categoryIndex].allergies.push(newMenuFields.allergies);
        updatedMenuItems[categoryIndex].prices.push(
          parseFloat(newMenuFields.price)
        );
        updatedMenuItems[categoryIndex].descriptions.push(
          newMenuFields.description
        );

        // Continue with updating the state or any other logic you need...
        console.log(updatedMenuItems);
        setMenuList(updatedMenuItems);

        // Now, updatedMenuItems reflects the updated structure.
      } else {
        // Find the category index
        let categoryIndex = updatedMenuItems.findIndex(
          (item) => item.category === selectedCategory
        );

        if (categoryIndex !== -1) {
          // Directly access and update the necessary arrays.
          updatedMenuItems[categoryIndex].menuItems.push(newMenuFields.name);
          updatedMenuItems[categoryIndex].allergies.push(
            newMenuFields.allergies
          );
          updatedMenuItems[categoryIndex].prices.push(
            parseFloat(newMenuFields.price)
          );
          updatedMenuItems[categoryIndex].descriptions.push(
            newMenuFields.description
          );

          // Now, updatedMenuItems reflects the updated structure.
          console.log(updatedMenuItems);
          setMenuList(updatedMenuItems);
        }
      }
      onGoBack();
    }

    // If you need to update the state or propagate these changes, do so here.
    // For example, if menuList is a state variable, you should call setState or equivalent here.
  };

  const onGoBack = () => {
    setNewMenu(false);
    setNewMenuFields({
      name: "",
      allergies: "",
      price: "",
      description: "",
      newCategory: "",
    });
    setMissingFields({
      name: false,
      allergies: false,
      price: false,
      description: false,
      category: false,
      newCategory: false,
    });
    setSelectedCategory("");
  };

  //ny kode:
  const handleMenuFileUploaded = (headers, data) => {
    const requiredHeaders = [
      "Kategori (hvilken kategori tilhører objektet?)",
      "Navn på menyobjekt",
      "Pris (kun siffer)",
      "Allergener (skilles med komma)",
      "Beskrivelse av objektet",
    ];

    const headersMatch =
      requiredHeaders.length === headers.length &&
      requiredHeaders.every(
        (header, index) => header.trim() === headers[index].trim()
      );

    if (!headersMatch) {
      alert(
        "Filen du lastet opp bruker feile headere. Pass på å kun benytte deg av følgende headere: " +
          requiredHeaders.join(", ")
      );
      return;
    }

    const processedData = data.reduce((acc, row) => {
      const category = row[0].trim();
      let categoryIndex = acc.findIndex((item) => item.category === category);

      if (categoryIndex === -1) {
        acc.push({
          category: category,
          alergener: [],
          descriptions: [],
          menuItems: [],
          prices: [],
        });
        categoryIndex = acc.length - 1;
      }

      acc[categoryIndex].menuItems.push(row[1] !== undefined ? row[1] : "");
      acc[categoryIndex].prices.push(
        row[2] !== undefined ? parseFloat(row[2]) : 0
      );
      acc[categoryIndex].alergener.push(row[3] !== undefined ? row[3] : "");
      acc[categoryIndex].descriptions.push(row[4] !== undefined ? row[4] : "");

      return acc;
    }, []);

    setMenuList(processedData);
  };

  //ny kode:

  const downloadExcel = () => {
    if (!menuList) return;

    const headers = [
      "Kategori (hvilken kategori tilhører objektet?)",
      "Navn på menyobjekt",
      "Pris (kun siffer)",
      "Allergener (skilles med komma)",
      "Beskrivelse av objektet",
    ];

    const data = [];

    menuList.forEach((categoryData) => {
      const { category, menuItems, prices, alergener, descriptions } =
        categoryData;

      // Assuming that all arrays (menuItems, prices, alergener, descriptions) have the same length
      for (let i = 0; i < menuItems.length; i++) {
        const row = [
          category,
          menuItems[i],
          prices[i],
          alergener[i],
          descriptions[i],
        ];
        data.push(row);
      }
    });

    console.log(data);
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

    // Set column widths
    worksheet["!cols"] = [
      { wpx: 200 }, // "Kategori (hvilken kategori tilhører objektet?)"
      { wpx: 200 }, // "Navn på menyobjekt"
      { wpx: 100 }, // "Pris"
      { wpx: 200 }, // "Allergener (skilles med komma)"
      { wpx: 300 }, // "Beskrivelse av objektet"
    ];

    // Add header styling
    headers.forEach((header, index) => {
      const cell = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!worksheet[cell]) worksheet[cell] = {};
      worksheet[cell].s = {
        font: {
          bold: true,
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
        fill: {
          fgColor: { rgb: "FFFFAA00" }, // Light orange fill
        },
      };
    });

    // Add border styling to all cells
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
        if (cell) {
          if (!cell.s) cell.s = {};
          cell.s.border = {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          };
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "MapliMenuItems.xlsx");
  };

  const translateText = async (text) => {
    try {
      const response = await fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/en/${text}`
      );
      const result = await response.json();
      console.log(
        `Translated "${text}" to "${result.choices[0].message.content}"`
      ); // Debugging line
      return result.choices[0].message.content; // Adjust based on your API's response structure
    } catch (error) {
      console.error(`Error translating text "${text}":`, error);
      return text; // Fallback to the original text in case of an error
    }
  };

  const translateObject = async (data) => {
    const translatedData = {};

    for (const key in data) {
      if (Array.isArray(data[key])) {
        translatedData[key] = await Promise.all(
          data[key].map(async (item) => {
            if (typeof item === "string") {
              return await translateText(item);
            } else if (typeof item === "object" && item !== null) {
              return await translateObject(item); // Recursive call for nested objects
            }
            return item;
          })
        );
      } else if (typeof data[key] === "object" && data[key] !== null) {
        translatedData[key] = await translateObject(data[key]); // Recursive call for nested objects
      } else if (typeof data[key] === "string") {
        translatedData[key] = await translateText(data[key]);
      } else {
        translatedData[key] = data[key];
      }
    }
    return translatedData;
  };

  const handleTranslate = async () => {
    const translatedData = await Promise.all(
      menuList.map(async (categoryData) => {
        const translatedCategory = await translateText(categoryData.category);
        const translatedMenuItems = await Promise.all(
          categoryData.menuItems.map(async (item) => await translateText(item))
        );
        const translatedAlergener = await Promise.all(
          categoryData.alergener.map(async (item) => await translateText(item))
        );
        const translatedDescriptions = await Promise.all(
          categoryData.descriptions.map(
            async (item) => await translateText(item)
          )
        );

        return {
          category: translatedCategory,
          menuItems: translatedMenuItems,
          alergener: translatedAlergener,
          prices: categoryData.prices, // Prices don't need translation
          descriptions: translatedDescriptions,
        };
      })
    );

    setMenuListEnglish(translatedData);
  };

  const test = () => {
    console.log(menuListEnglish);
  };

  return (
    <>
      <div className='menuPageContainer'>
        {!newMenu ? (
          <>
            <label className='editPageLabel'>
              {lokasjonstype === "restaurant" ? "Menylister" : "Aktiviteter"}
              {editLanguage === "english" ? (
                <>
                  {" "}
                  <a
                    href='#'
                    class='editPageLink'
                    onClick={() => {
                      handleTranslate();
                    }}>
                    Oversett automatisk
                  </a>
                </>
              ) : (
                <></>
              )}
            </label>

            {editLanguage !== "english" ? (
              menuList.length > 0 ? (
                menuList.map((categoryData, index) => (
                  <div key={index} className='menuListDropdown'>
                    <h3
                      onClick={() =>
                        toggleMenuVisibility(categoryData.category)
                      }>
                      {categoryData.category} ⯆
                    </h3>
                    {visibleMenus[categoryData.category] && (
                      <div className='menuListShown'>
                        {categoryData.menuItems.map((itemName, itemIndex) => (
                          <div key={itemIndex} className='menuListShownItem'>
                            <div style={{ fontWeight: "bold" }}>
                              {itemName.charAt(0).toUpperCase() +
                                itemName.slice(1)}
                            </div>
                            <div style={{ color: "#5d3160" }}>
                              {categoryData.prices[itemIndex]} NOK
                            </div>
                            <div style={{ marginTop: "5px" }}>
                              {categoryData.descriptions[itemIndex]
                                .charAt(0)
                                .toUpperCase() +
                                categoryData.descriptions[itemIndex].slice(1)}
                            </div>
                            <div style={{ marginTop: "5px" }}>
                              Allergener: {categoryData.alergener[itemIndex]}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <></>
              )
            ) : (
              <>
                {menuListEnglish.length > 0 ? (
                  menuListEnglish.map((categoryData, index) => (
                    <div key={index} className='menuListDropdown'>
                      <h3
                        onClick={() =>
                          toggleMenuVisibility(categoryData.category)
                        }>
                        {categoryData.category} ⯆
                      </h3>
                      {visibleMenus[categoryData.category] && (
                        <div className='menuListShown'>
                          {categoryData.menuItems.map((itemName, itemIndex) => (
                            <div key={itemIndex} className='menuListShownItem'>
                              <div style={{ fontWeight: "bold" }}>
                                {itemName.charAt(0).toUpperCase() +
                                  itemName.slice(1)}
                              </div>
                              <div style={{ color: "#5d3160" }}>
                                {categoryData.prices[itemIndex]} NOK
                              </div>
                              <div style={{ marginTop: "5px" }}>
                                {categoryData.descriptions[itemIndex]
                                  .charAt(0)
                                  .toUpperCase() +
                                  categoryData.descriptions[itemIndex].slice(1)}
                              </div>
                              <div style={{ marginTop: "5px" }}>
                                Allergener: {categoryData.alergener[itemIndex]}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </>
            )}

            {editLanguage !== "english" ? (
              <>
                <a
                  href='#'
                  onClick={() => {
                    setNewMenu(true);
                  }}
                  className='editPageLink'
                  style={{ marginTop: "15px" }}>
                  Legg til ny +
                </a>
                <div className='editPageMenuExcelContainer'>
                  <button
                    onClick={downloadExcel}
                    className='editPageExcelDownloadButton'>
                    Klikk for å laste ned menyobjektene i en excelfil for
                    enklere å laste opp flere menyobjekter på samme tid
                  </button>
                  <ExcelFileUpload onFileUploaded={handleMenuFileUploaded} />
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <label className='editPageLabel'>
              {lokasjonstype === "restaurant"
                ? "Legg til nytt menyobjekt"
                : "Legg til ny aktivitet"}
            </label>
            <div className='menuPageItemInfoContainer'>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "38%",
                }}>
                <label className='editPageLabel'>Navn</label>
                <input
                  placeholder='Navn'
                  value={newMenuFields.name}
                  onChange={(x) => {
                    setNewMenuFields((prevItems) => ({
                      ...prevItems,
                      name: x.target.value,
                    }));
                  }}
                />
                <label
                  style={{
                    display: missingFields.name ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe!
                </label>
              </div>
              {lokasjonstype === "restaurant" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "38%",
                  }}>
                  <label className='editPageLabel'>Allergener</label>
                  <input
                    placeholder='Allergener'
                    value={newMenuFields.allergies}
                    onChange={(x) => {
                      setNewMenuFields((prevItems) => ({
                        ...prevItems,
                        allergies: x.target.value,
                      }));
                    }}
                  />
                  <label
                    style={{
                      display: missingFields.allergies ? "block" : "none",
                      color: "#f9615b",
                    }}>
                    Her mangler det noe!
                  </label>
                </div>
              ) : (
                <></>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className='editPageLabel'>Pris</label>
                <input
                  placeholder='Pris'
                  value={newMenuFields.price}
                  onChange={(x) => {
                    setNewMenuFields((prevItems) => ({
                      ...prevItems,
                      price: x.target.value,
                    }));
                  }}
                />
                <label
                  style={{
                    display: missingFields.price ? "block" : "none",
                    color: "#f9615b",
                  }}>
                  Her mangler det noe!
                </label>
              </div>
            </div>
            <div
              className='menuPageItemInfoContainer'
              style={{ display: "flex", flexDirection: "column" }}>
              <label className='editPageLabel'>
                Skriv en kort beskrivende tekst{" "}
                {lokasjonstype === "restaurant"
                  ? ""
                  : '(linker som starter med "https" og "www" kan inkluderes)'}
              </label>
              <textarea
                placeholder='Skriv en kort beskrivende tekst'
                style={{ height: "100px" }}
                value={newMenuFields.description}
                onChange={(x) => {
                  setNewMenuFields((prevItems) => ({
                    ...prevItems,
                    description: x.target.value,
                  }));
                }}
              />
              <label
                style={{
                  display: missingFields.description ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe!
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
                width: "25%",
              }}>
              <label className='editPageLabel'>Kategori</label>
              <select
                style={{ width: "150px" }}
                onChange={(x) => {
                  setSelectedCategory(x.target.value);
                }}>
                <option value=''>Velg kategori</option>
                <option value='newCategory'>Ny kategori</option>
                {Object.keys(menuList).map((element, index) => (
                  <option key={index} value={element}>
                    {element}
                  </option>
                ))}
              </select>

              <label
                style={{
                  display: missingFields.category ? "block" : "none",
                  color: "#f9615b",
                }}>
                Her mangler det noe!
              </label>
              {selectedCategory === "newCategory" ? (
                <>
                  <input
                    placeholder='Ny kategori'
                    style={{ marginTop: "10px" }}
                    value={newMenuFields.newCategory}
                    onChange={(x) => {
                      setNewMenuFields((prevItems) => ({
                        ...prevItems,
                        newCategory: x.target.value,
                      }));
                    }}
                  />
                  <label
                    style={{
                      display: missingFields.newCategory ? "block" : "none",
                      color: "#f9615b",
                    }}>
                    Her mangler det noe!
                  </label>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <button
                onClick={addNewMenuItem}
                className='universalButtonRed'
                style={{
                  width: "150px",
                  marginTop: "25px",
                  marginRight: "10px",
                }}>
                Legg til
              </button>
              <button onClick={onGoBack} className='universalButton'>
                Avbryt
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EditMenu;
