import React, { useEffect } from "react";
import "../css/homepage.css";
import AppleBadge from "../assets/images/apple-appstore-badge.svg";
import GooglePlayBadge from "../assets/images/googleplay.png";
import Footer from "../components/footer";
import HeaderHomepage from "../components/headerHomepage";

const PricesPage = (props) => {
  useEffect(() => {
    document.title = "Mapli";
  }, []);

  return (
    <div className='homePageContainer'>
      <HeaderHomepage />

      <div className='homePageMainContainer'>
        <div className='loginpageRight' style={{ width: "40%" }}>
          <div className='pricesForm'>
            <h2>Plasser din bedrift på kartet for</h2>
            <div class='pricing-amount-block'>
              <span class='pricing-amount-figure'>150</span>
              <span class='pricing-amount-currency'>/mnd</span>
            </div>

            <button
              className='loginButton'
              style={{ width: "10vw" }}
              onClick={() => {}}>
              Opprett bruker
            </button>
            <a
              href='/login'
              style={{
                display: "flex",
                color: "black",
                marginTop: "10px",
                marginBottom: "25px",
              }}>
              <div style={{ textDecoration: "underline" }}>
                Har du allerede en bruker?
              </div>
              <div className='loginIfUser'>Logg inn</div>
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PricesPage;
