import React from "react";
import "../css/homepage.css";
import MapliLogoOrange from "../assets/images/Oransje_Logo.svg";

const HeaderHomepage = (props) => {
  return (
    <header className='homepageHeader'>
      <a href='/' className='headerLeft'>
        <img src={MapliLogoOrange} height='40px' />
      </a>
      <div className='headerRight'>
        <a href='/prices' className='headerPrices'>
          Priser
        </a>
        <a href='/faq' className='headerPrices'>
          FAQ
        </a>
        <a href='/login'>
          <p className='businessPageLoginButton'>For bedrifter</p>
        </a>
      </div>
    </header>
  );
};

export default HeaderHomepage;
