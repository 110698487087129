// FileUpload.js
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

const ExcelFileUpload = ({ onFileUploaded }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Extract headers as first row

        const headers = json[0]; // First row as headers
        const rows = json.slice(1); // Remaining rows as data

        onFileUploaded(headers, rows);
      };

      reader.readAsArrayBuffer(file);
    },
    [onFileUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      style={{
        border: "2px dashed #cccccc",
        padding: "20px",
        textAlign: "center",
      }}>
      <input {...getInputProps()} />
      <p>Dreg og slipp en Excel fil her, eller klikk for å velge en</p>
    </div>
  );
};

export default ExcelFileUpload;
