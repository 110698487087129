import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import "../css/homepage.css";
import Footer from "../components/footer";
import Header from "../components/header";
import HubSpotForm from "../components/hubspotForm";

const db = firestore;

const faqList = {
  listItem1: {
    title: "Spørsmål nr 1",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially",
  },
};

const FaqPage = (props) => {
  const [visibleMenus, setVisibleMenus] = useState({});
  const [faqList, setFaqList] = useState({});
  const location = useLocation();

  useEffect(() => {
    const fetchFaqData = async () => {
      const faqRef = collection(db, "faq");
      const querySnapshot = await getDocs(faqRef);

      const data = {};
      querySnapshot.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      setFaqList(data);

      // Initialize visibility state
      const menuVisibility = {};
      Object.keys(data).forEach((element) => {
        menuVisibility[element] = false;
      });
      setVisibleMenus(menuVisibility);

      // Check for URL fragment after data is set
      const hash = location.hash.substring(1);
      if (hash && data[hash]) {
        setVisibleMenus((prevVisibleMenus) => ({
          ...prevVisibleMenus,
          [hash]: true,
        }));
        // Scroll to the element
        setTimeout(() => {
          document.getElementById(hash).scrollIntoView({ behavior: "smooth" });
        }, 0); // Ensure this runs after render
      }
    };
    fetchFaqData();
  }, [location]);

  const toggleMenuVisibility = (element) => {
    setVisibleMenus((prevVisibleMenus) => ({
      ...prevVisibleMenus,
      [element]: !prevVisibleMenus[element],
    }));
  };

  useEffect(() => {
    document.title = "Mapli";
  }, []);

  return (
    <div className='homePageContainer'>
      <Header faq={true} />

      <div
        className='BusinessPagesMainContainer'
        style={{
          paddingLeft: "50px",
          paddingRight: "50px",
          paddingBottom: "50px",
          flexDirection: "column",
          paddingTop: "50px",
          minHeight: "75vh",
        }}>
        <h2 className='editPageLabel'>Ofte stilte spørsmål</h2>
        <div className='faq_maincontainer'>
          <div className='faq_dropdownContainer'>
            {Object.keys(faqList).length > 0 ? (
              Object.entries(faqList)
                .sort(([, a], [, b]) => a.sort - b.sort)
                .map(([element], index) => (
                  <div key={index} id={element} className='faq_dropdown'>
                    <div
                      className='dropdownItemContainer'
                      style={
                        visibleMenus[element]
                          ? {
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                            }
                          : {}
                      }
                      onClick={() => toggleMenuVisibility(element)}>
                      <label>{faqList[element].title}</label>
                      <label>⯆</label>
                    </div>
                    {visibleMenus[element] ? (
                      <div className='box'>{faqList[element].description}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))
            ) : (
              <></>
            )}
          </div>
          <div className='faq_right'>
            <div className='box'>
              <label className='editPageLabel'>
                Fant du ikke det du lette etter?
              </label>
              <label className='editPageLabel'>Kontakt oss!</label>
              <HubSpotForm />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FaqPage;
