import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import FileUpload from "./fileUpload";

const db = firestore;

const EditAbout = ({
  regularOpeningHours,
  setRegularOpeningHours,
  setRegularClosingHours,
  regularClosingHours,
  newCustomOpeningHours,
  setNewCustomOpeningHours,
  locationInfo,
  newProfileImage,
  setNewProfileImage,
  fileImage,
  setFileImage,
  fileName,
  setFileName,
  textInformation,
  setTextInformation,
  newProfileFile,
  setNewProfileFile,
  newProfileImagePreview,
  setNewProfileImagePreview,
  editLanguage,
}) => {
  const [inputType, setInputType] = useState({
    omon: "text",
    smon: "text",
    otue: "text",
    stue: "text",
    owed: "text",
    swed: "text",
    othu: "text",
    sthu: "text",
    ofri: "text",
    sfri: "text",
    osat: "text",
    ssat: "text",
    osun: "text",
    ssun: "text",
  });
  const [openEditPictureModal, setOpenEditPictureModal] = useState(false);

  let { edit } = useParams();

  const handleFocus = (key) => {
    setInputType((prev) => ({ ...prev, [key]: "time" }));
  };

  const handleBlur = (key, value) => {
    if (!value) {
      setInputType((prev) => ({ ...prev, [key]: "text" }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setNewProfileFile(file);
      setNewProfileImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSave = () => {
    if (!newProfileFile) {
      alert("No file selected!");
      return;
    }

    const storage = getStorage();
    const storageRef = ref(storage, `images/${newProfileFile.name}`);

    uploadBytes(storageRef, newProfileFile).then((snapshot) => {
      console.log("Uploaded a blob or file!");

      // Get the download URL
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log("File available at", downloadURL);

        // Save the download URL to Firestore
        const collectionRef = doc(db, "lokasjoner", edit);
        updateDoc(collectionRef, { mainProfileImage: downloadURL })
          .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      });
    });
  };

  const onTranslateText = async (x) => {
    if (x === "description") {
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/${x}/${textInformation.description}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setTextInformation({
            ...textInformation,
            description_enUS: data.choices[0].message.content,
          });
        });
    } else if (x === "tags") {
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/${x}/${textInformation.tags}`
      )
        .then((res) => res.json())
        .then((data) => {
          const translationArray = data.choices[0].message.content
            .split(",")
            .map((word) => word.trim());
          console.log(translationArray);
          setTextInformation({
            ...textInformation,
            tags_enUS: translationArray,
          });
        });
    } else if (x === "maintags") {
      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/translateText/tags/${textInformation.locationTags}`
      )
        .then((res) => res.json())
        .then((data) => {
          const translationArray = data.choices[0].message.content
            .split(",")
            .map((word) => word.trim());
          console.log(translationArray);
          setTextInformation({
            ...textInformation,
            locationTags_enUS: translationArray,
          });
        });
    }
  };

  return (
    <>
      <div>
        {/* Top */}
        <div className='editAboutTop'>
          {/* Picture */}
          <div className='editAboutPictureContainer'>
            <div className='editPicture'>
              {newProfileImage && newProfileImage.length > 1 ? (
                <img
                  src={newProfileImage}
                  alt='Preview'
                  className='editPicture'
                />
              ) : (
                <></>
              )}
            </div>
            <a
              className='editPageLink'
              href='#'
              onClick={() => {
                setOpenEditPictureModal(true);
              }}>
              Rediger bilde
            </a>
          </div>
          {/* Name + Tags */}
          <div className='editAboutNameTagsContainer'>
            {editLanguage !== "english" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className='editPageLabel'>Navn</label>
                <input
                  placeholder='Navn'
                  value={textInformation.name}
                  onChange={(x) => {
                    setTextInformation({
                      ...textInformation,
                      name: x.target.value,
                    });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "15px",
                  width: "56%",
                }}>
                <label className='editPageLabel'>
                  {editLanguage !== "english" ? (
                    "Tags"
                  ) : (
                    <>
                      Tags (engelske tags){" "}
                      <a
                        href='#'
                        class='editPageLink'
                        onClick={() => {
                          onTranslateText("tags");
                        }}>
                        Oversett automatisk
                      </a>
                    </>
                  )}
                </label>
                <input
                  placeholder='Søkeord for lokasjonen (eg. burger, asiatisk, konserter etc.)'
                  value={
                    editLanguage !== "english"
                      ? textInformation.tags.join(",")
                      : textInformation.tags_enUS.join(",")
                  }
                  onChange={(e) => {
                    if (editLanguage !== "english") {
                      // Get the current input value directly
                      const inputValue = e.target.value;

                      // Split the input based on commas without altering spaces
                      const newTags = inputValue.split(",").map((tag) => {
                        const trimmedTag = tag.trim();
                        return (
                          trimmedTag.charAt(0).toUpperCase() +
                          trimmedTag.slice(1).toLowerCase()
                        );
                      });

                      // Update the state with these new tags without trimming spaces
                      setTextInformation({ ...textInformation, tags: newTags });
                    } else {
                      // Get the current input value directly
                      const inputValue = e.target.value;

                      // Split the input based on commas without altering spaces
                      const newTags = inputValue.split(",").map((tag) => {
                        const trimmedTag = tag.trim();
                        return (
                          trimmedTag.charAt(0).toUpperCase() +
                          trimmedTag.slice(1).toLowerCase()
                        );
                      });

                      // Update the state with these new tags without trimming spaces
                      setTextInformation({
                        ...textInformation,
                        tags_enUS: newTags,
                      });
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "42%",
                  marginTop: "15px",
                }}>
                <label className='editPageLabel'>
                  {editLanguage !== "english" ? (
                    "Hoved tags"
                  ) : (
                    <>
                      Hoved tags (engelske tags){" "}
                      <a
                        href='#'
                        class='editPageLink'
                        onClick={() => {
                          onTranslateText("maintags");
                        }}>
                        Oversett automatisk
                      </a>
                    </>
                  )}{" "}
                  <a href='/faq#tags'>
                    <BsFillQuestionCircleFill color='#4550e2' size='17px' />
                  </a>
                </label>
                <input
                  placeholder='Synlige tags på profilen (eg. italiensk, bar, etc.)'
                  value={
                    editLanguage !== "english"
                      ? textInformation.locationTags.join(",")
                      : textInformation.locationTags_enUS.join(",")
                  }
                  onChange={(e) => {
                    if (editLanguage !== "english") {
                      // Get the current input value directly
                      const inputValue = e.target.value;

                      // Split the input based on commas without altering spaces
                      const newTags = inputValue.split(",");

                      // Update the state with these new tags without trimming spaces
                      setTextInformation({
                        ...textInformation,
                        locationTags: newTags,
                      });
                    } else {
                      // Get the current input value directly
                      const inputValue = e.target.value;

                      // Split the input based on commas without altering spaces
                      const newTags = inputValue
                        .split(",")
                        .map((tag) => tag.trim());

                      // Update the state with these new tags without trimming spaces
                      setTextInformation({
                        ...textInformation,
                        locationTags_enUS: newTags,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}>
              <label className='editPageLabel'>Adresse</label>
              <input
                placeholder='Adresse'
                value={textInformation.adress}
                onChange={(x) => {
                  setTextInformation({
                    ...textInformation,
                    adress: x.target.value,
                  });
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
            }}>
            <label className='editPageLabel'>
              {editLanguage !== "english" ? (
                "Skriv en beskrivende tekst om lokasjonen (max 70 ord)"
              ) : (
                <>
                  Skriv en beskrivende tekst om lokasjonen (max 70 ord) (engelsk
                  beskrivelse){" "}
                  <a
                    href='#'
                    class='editPageLink'
                    onClick={() => {
                      onTranslateText("description");
                    }}>
                    Oversett automatisk
                  </a>
                </>
              )}
            </label>
            <textarea
              placeholder='Om lokasjonen (max 70 ord)'
              value={
                editLanguage !== "english"
                  ? textInformation.description
                  : textInformation.description_enUS
              }
              onChange={(x) => {
                if (editLanguage !== "english") {
                  setTextInformation({
                    ...textInformation,
                    description: x.target.value,
                  });
                } else {
                  setTextInformation({
                    ...textInformation,
                    description_enUS: x.target.value,
                  });
                }
              }}
            />
          </div>

          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}>
              <label className='editPageLabel'>Link til nettside</label>
              <input
                placeholder='Link til nettside'
                value={textInformation.websiteLink}
                onChange={(x) => {
                  setTextInformation({
                    ...textInformation,
                    websiteLink: x.target.value,
                  });
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}>
              <label className='editPageLabel'>Åpningstider</label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "15px",
                  justifyContent: "flex-start",
                }}>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.omon === "time" || regularOpeningHours.mon) && (
                    <label
                      htmlFor='omon'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularOpeningHours.mon || inputType.omon === "time"
                            ? "block"
                            : "none",
                      }}>
                      Mandag
                    </label>
                  )}
                  <input
                    id='omon'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.omon}
                    placeholder={
                      inputType.omon === "text" ? "Mandag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("omon")}
                    onBlur={() => handleBlur("omon", regularOpeningHours.mon)}
                    value={
                      regularOpeningHours.mon
                        ? `${String(regularOpeningHours.mon.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularOpeningHours.mon.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          mon: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          mon: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.otue === "time" || regularOpeningHours.tue) && (
                    <label
                      htmlFor='otue'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularOpeningHours.tue || inputType.otue === "time"
                            ? "block"
                            : "none",
                      }}>
                      Tirsdag
                    </label>
                  )}
                  <input
                    id='otue'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.otue}
                    placeholder={
                      inputType.otue === "text" ? "Tirsdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("otue")}
                    onBlur={() => handleBlur("otue", regularOpeningHours.tue)}
                    value={
                      regularOpeningHours.tue
                        ? `${String(regularOpeningHours.tue.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularOpeningHours.tue.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          tue: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          tue: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.owed === "time" || regularOpeningHours.wed) && (
                    <label
                      htmlFor='owed'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularOpeningHours.wed || inputType.owed === "time"
                            ? "block"
                            : "none",
                      }}>
                      Onsdag
                    </label>
                  )}
                  <input
                    id='owed'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.owed}
                    placeholder={
                      inputType.owed === "text" ? "Onsdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("owed")}
                    onBlur={() => handleBlur("owed", regularOpeningHours.wed)}
                    value={
                      regularOpeningHours.wed
                        ? `${String(regularOpeningHours.wed.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularOpeningHours.wed.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          wed: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          wed: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.othu === "time" || regularOpeningHours.thu) && (
                    <label
                      htmlFor='othu'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularOpeningHours.thu || inputType.othu === "time"
                            ? "block"
                            : "none",
                      }}>
                      Torsdag
                    </label>
                  )}
                  <input
                    id='othu'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.othu}
                    placeholder={
                      inputType.othu === "text" ? "Torsdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("othu")}
                    onBlur={() => handleBlur("othu", regularOpeningHours.thu)}
                    value={
                      regularOpeningHours.thu
                        ? `${String(regularOpeningHours.thu.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularOpeningHours.thu.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          thu: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          thu: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 20,
                }}>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.ofri === "time" || regularOpeningHours.fri) && (
                    <label
                      htmlFor='ofri'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularOpeningHours.fri || inputType.ofri === "time"
                            ? "block"
                            : "none",
                      }}>
                      Fredag
                    </label>
                  )}
                  <input
                    id='ofri'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.ofri}
                    placeholder={
                      inputType.ofri === "text" ? "Fredag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("ofri")}
                    onBlur={() => handleBlur("ofri", regularOpeningHours.fri)}
                    value={
                      regularOpeningHours.fri
                        ? `${String(regularOpeningHours.fri.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularOpeningHours.fri.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          fri: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          fri: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.osat === "time" || regularOpeningHours.sat) && (
                    <label
                      htmlFor='osat'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularOpeningHours.sat || inputType.osat === "time"
                            ? "block"
                            : "none",
                      }}>
                      Lørdag
                    </label>
                  )}
                  <input
                    id='osat'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.osat}
                    placeholder={
                      inputType.osat === "text" ? "Lørdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("osat")}
                    onBlur={() => handleBlur("osat", regularOpeningHours.sat)}
                    value={
                      regularOpeningHours.sat
                        ? `${String(regularOpeningHours.sat.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularOpeningHours.sat.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          sat: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          sat: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.osun === "time" || regularOpeningHours.sun) && (
                    <label
                      htmlFor='osun'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularOpeningHours.sun || inputType.osun === "time"
                            ? "block"
                            : "none",
                      }}>
                      Søndag
                    </label>
                  )}
                  <input
                    id='osun'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.osun}
                    placeholder={
                      inputType.osun === "text" ? "Søndag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("osun")}
                    onBlur={() => handleBlur("osun", regularOpeningHours.sun)}
                    value={
                      regularOpeningHours.sun
                        ? `${String(regularOpeningHours.sun.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularOpeningHours.sun.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          sun: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularOpeningHours((prevState) => ({
                          ...prevState,
                          sun: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}>
              <label className='editPageLabel'>Stengetider</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "15px",
                  justifyContent: "flex-start",
                }}>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.smon === "time" || regularClosingHours.mon) && (
                    <label
                      htmlFor='smon'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularClosingHours.mon || inputType.smon === "time"
                            ? "block"
                            : "none",
                      }}>
                      Mandag
                    </label>
                  )}
                  <input
                    id='smon'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.smon}
                    placeholder={
                      inputType.smon === "text" ? "Mandag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("smon")}
                    onBlur={() => handleBlur("smon", regularClosingHours.mon)}
                    value={
                      regularClosingHours.mon
                        ? `${String(regularClosingHours.mon.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularClosingHours.mon.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          mon: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          mon: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.stue === "time" || regularClosingHours.tue) && (
                    <label
                      htmlFor='stue'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularClosingHours.tue || inputType.stue === "time"
                            ? "block"
                            : "none",
                      }}>
                      Tirsdag
                    </label>
                  )}
                  <input
                    id='stue'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.stue}
                    placeholder={
                      inputType.stue === "text" ? "Tirsdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("stue")}
                    onBlur={() => handleBlur("stue", regularClosingHours.tue)}
                    value={
                      regularClosingHours.tue
                        ? `${String(regularClosingHours.tue.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularClosingHours.tue.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          tue: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          tue: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.swed === "time" || regularClosingHours.wed) && (
                    <label
                      htmlFor='swed'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularClosingHours.wed || inputType.swed === "time"
                            ? "block"
                            : "none",
                      }}>
                      Onsdag
                    </label>
                  )}
                  <input
                    id='swed'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.swed}
                    placeholder={
                      inputType.swed === "text" ? "Onsdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("swed")}
                    onBlur={() => handleBlur("swed", regularClosingHours.wed)}
                    value={
                      regularClosingHours.wed
                        ? `${String(regularClosingHours.wed.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularClosingHours.wed.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          wed: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          wed: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.sthu === "time" || regularClosingHours.thu) && (
                    <label
                      htmlFor='sthu'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularClosingHours.thu || inputType.sthu === "time"
                            ? "block"
                            : "none",
                      }}>
                      Torsdag
                    </label>
                  )}
                  <input
                    id='sthu'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.sthu}
                    placeholder={
                      inputType.sthu === "text" ? "Torsdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("sthu")}
                    onBlur={() => handleBlur("sthu", regularClosingHours.thu)}
                    value={
                      regularClosingHours.thu
                        ? `${String(regularClosingHours.thu.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularClosingHours.thu.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          thu: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          thu: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 20,
                }}>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.sfri === "time" || regularClosingHours.fri) && (
                    <label
                      htmlFor='sfri'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularClosingHours.fri || inputType.sfri === "time"
                            ? "block"
                            : "none",
                      }}>
                      Fredag
                    </label>
                  )}
                  <input
                    id='sfri'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.sfri}
                    placeholder={
                      inputType.sfri === "text" ? "Fredag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("sfri")}
                    onBlur={() => handleBlur("sfri", regularClosingHours.fri)}
                    value={
                      regularClosingHours.fri
                        ? `${String(regularClosingHours.fri.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularClosingHours.fri.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          fri: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          fri: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.ssat === "time" || regularClosingHours.sat) && (
                    <label
                      htmlFor='ssat'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularClosingHours.sat || inputType.ssat === "time"
                            ? "block"
                            : "none",
                      }}>
                      Lørdag
                    </label>
                  )}
                  <input
                    id='ssat'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.ssat}
                    placeholder={
                      inputType.ssat === "text" ? "Lørdag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("ssat")}
                    onBlur={() => handleBlur("ssat", regularClosingHours.sat)}
                    value={
                      regularClosingHours.sat
                        ? `${String(regularClosingHours.sat.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularClosingHours.sat.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          sat: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          sat: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "17%",
                  }}>
                  {(inputType.ssun === "time" || regularClosingHours.sun) && (
                    <label
                      htmlFor='ssun'
                      style={{
                        position: "absolute",
                        top: "-16px",
                        left: "5px",
                        fontSize: "smaller",
                        color: "#666",
                        display:
                          regularClosingHours.sun || inputType.ssun === "time"
                            ? "block"
                            : "none",
                      }}>
                      Søndag
                    </label>
                  )}
                  <input
                    id='ssun'
                    style={{ width: "90%" }}
                    className='timepicker'
                    type={inputType.ssun}
                    placeholder={
                      inputType.ssun === "text" ? "Søndag (stengt)" : ""
                    }
                    onFocus={() => handleFocus("ssun")}
                    onBlur={() => handleBlur("ssun", regularClosingHours.sun)}
                    value={
                      regularClosingHours.sun
                        ? `${String(regularClosingHours.sun.hour).padStart(
                            2,
                            "0"
                          )}:${String(regularClosingHours.sun.minutes).padStart(
                            2,
                            "0"
                          )}`
                        : ""
                    }
                    onChange={(x) => {
                      if (x.target.value === "") {
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          sun: null,
                        }));
                      } else {
                        const [hrs, mins] = x.target.value
                          .split(":")
                          .map(Number);
                        setRegularClosingHours((prevState) => ({
                          ...prevState,
                          sun: {
                            // Ensure hours and minutes are stored as numbers
                            hour: hrs || 0, // Fallback to 0 if hrs is NaN
                            minutes: mins || 0, // Fallback to 0 if mins is NaN
                          },
                        }));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}>
              <label className='editPageLabel'>
                Kan gjester reservere bord på forhånd? Link og/eller
                telefonnummer til reservasjon
              </label>
              <input
                placeholder='Link til booking'
                value={textInformation.bookingLink}
                onChange={(x) => {
                  setTextInformation({
                    ...textInformation,
                    bookingLink: x.target.value,
                  });
                }}
              />
              <input
                style={{ marginTop: "10px" }}
                placeholder='Telefon til booking'
                value={textInformation.phoneLink}
                onChange={(x) => {
                  setTextInformation({
                    ...textInformation,
                    phoneLink: x.target.value,
                  });
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {editLanguage !== "english" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}>
              <label className='editPageLabel'>
                Last opp bilder av lokasjonen (max 4)
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                }}>
                <div style={{ display: "flex" }}>
                  <FileUpload
                    id='file1'
                    fileImage={fileImage}
                    setFileImage={setFileImage}
                    setFileName={setFileName}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <FileUpload
                      id='file2'
                      fileImage={fileImage}
                      setFileImage={setFileImage}
                      setFileName={setFileName}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <FileUpload
                    id='file3'
                    fileImage={fileImage}
                    setFileImage={setFileImage}
                    setFileName={setFileName}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <FileUpload
                      id='file4'
                      fileImage={fileImage}
                      setFileImage={setFileImage}
                      setFileName={setFileName}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* Modals */}
      <Modal
        isOpen={openEditPictureModal}
        onRequestClose={() => (
          setOpenEditPictureModal(false), setNewProfileImage("")
        )}
        className='modalStyle'
        style={{
          content: {
            width: "17%",
          },
        }}>
        <div className='editActiveModal'>
          <label className='editPageLabel'>Endre lokasjonens hovedbilde</label>
          <div className='editActiveModalButtonContainer'>
            <input type='file' onChange={handleImageChange} />
            {newProfileImagePreview && (
              <img
                src={newProfileImagePreview}
                alt='Preview'
                height='125px'
                width='125px'
              />
            )}
            <button
              onClick={() => (
                setOpenEditPictureModal(false),
                setNewProfileImage(newProfileImagePreview)
              )}
              className='universalButtonRed'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Bruk bilde
            </button>
            <button
              onClick={() => (
                setOpenEditPictureModal(false), setNewProfileImagePreview("")
              )}
              className='universalButton'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Avbryt
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditAbout;
