import React from "react";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import TextBoxToJson from "./components/textBoxToJson";
import MapWidget from "./widget/mapWidget";
import HomePage from "./Homepage";
import LoginPage from "./landingPage/LoginPage";
import PricesPage from "./landingPage/Prices";
import FaqPage from "./landingPage/Faq";
import BusinessPages from "./newBusinessPages/businessPages";
import MenuWidget from "./widget/menuWidget";

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/prices' element={<PricesPage />} />
        <Route path='/faq' element={<FaqPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/login/:page' element={<LoginPage />} />
        <Route path='/login/:page' element={<LoginPage />} />
        <Route path='/mapWidget' element={<MapWidget />} />
        <Route path='/menuWidget/:menu' element={<MenuWidget />} />
        <Route path='/myBusiness' element={<BusinessPages />} />
        <Route path='/myBusiness/:page' element={<BusinessPages />} />
        <Route path='/myBusiness/:page/:edit' element={<BusinessPages />} />
      </Routes>
    </>
  );
}

export default App;
