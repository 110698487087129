import React from "react";
import Lokasjoner_Ikon from "../../assets/images/Lokasjoner_Ikon.svg";
import Abonnement_Ikon from "../../assets/images/Abonnement_Ikon.svg";
import Arrangementer_Ikon from "../../assets/images/Annonsesenter_Ikon.svg";
import Profil_Ikon from "../../assets/images/Profil_Ikon.svg";

const WelcomePage = ({ currentBusiness }) => {
  return (
    <>
      <div className='welcomePage'>
        <h1>
          Velkommen{" "}
          <div
            style={{
              color: "#f9615b",
              marginRight: "7px",
              marginLeft: "7px",
            }}>
            {currentBusiness}!
          </div>
          Hva vil du gjøre?
        </h1>
        <div className='welcomePageContainer'>
          <div className='welcomePageSections'>
            <label>Mine lokasjoner</label>
            <a
              href='/MyBusiness/myLocations'
              className='box'
              style={{ height: "100%" }}>
              <img src={Lokasjoner_Ikon} style={{ height: "150px" }} />
            </a>
          </div>

          <div className='welcomePageSections'>
            <label>Abonnement</label>
            <a
              href='/MyBusiness/subscription'
              className='box'
              style={{ height: "70%" }}>
              <img src={Abonnement_Ikon} style={{ height: "125px" }} />
            </a>
          </div>
          <div className='welcomePageSections'>
            <label>Arrangementer</label>
            <a
              href='/MyBusiness/events'
              className='box'
              style={{ height: "60%" }}>
              <img src={Arrangementer_Ikon} />
            </a>
          </div>
          <div className='welcomePageSections'>
            <label>Profil</label>
            <a
              href='/MyBusiness/profile'
              className='box'
              style={{ height: "50%" }}>
              <img src={Profil_Ikon} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
